/* ==========================================================================
   # FLYOUT
   ========================================================================== */

$flyout-breakpoint-tablet:  md;
$flyout-breakpoint-desktop: lg;
$flyout-transition:         .35s cubic-bezier(0.25, 0.46, 0.45, 0.94);





.flyout {
	position: absolute;
	left: 0;
	right: 0;
	top: 100%;
	height: calc( var(--window-height) - var(--blocked-height) );
	overflow: hidden;
}





/* Content
   ========================================================================== */

.flyout__content {
	transition: transform $flyout-transition;

	.flyout.is-opening &,
	.flyout.is-open & {
		transform: translate3D(0, 0, 0);
	}

	&,
	.flyout.is-closing & {
		transform: translate3D(0, -100%, 0);
	}
}





/* Background
   ========================================================================== */

.flyout-bg {
	background-color: rgba(black, .7);
	display: block;
	position: absolute;
	top:    0;
	bottom: 0;
	right:  0;
	left:   0;
	transition: opacity $flyout-transition;

	@include mq($from: $flyout-breakpoint-tablet) {
		top: 0;
	}

	.flyout.is-opening &,
	.flyout.is-open & {
		opacity: 1;
	}

	&,
	.flyout.is-closing & {
		opacity: 0;
	}
}





/* Section
   ========================================================================== */

.flyout__section {
	background-color: $color-white;

	.section__inner {

		@include mq($until: $flyout-breakpoint-tablet) {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

/**
 * newsletter module
 *
 *
 */

.newsletter-subscription {

	.section__inner {
		padding-bottom: calc(#{var(--grid-gap)} * 4);
		padding-top: calc(#{var(--grid-gap)} * 3);

		@include mq($from: md) {
			padding-bottom: calc(#{var(--grid-gap)} * 2);
			padding-top: calc(#{var(--grid-gap)} * 1.75);

			display: grid;
			grid-column-gap: var(--grid-gap);
			grid-template-columns: repeat(12, 1fr);

			.section__content {
				grid-column: 3 / 11;
			}
		}

		@include mq($from: xl) {
			.section__content {
				grid-column: 4 / 10;
			}
		}
	}
}

.newsletter-subscription__headline {
	font-size: var(--font-size-heading-xl);
	margin-bottom: 1rem;
}

.newsletter-subscription__form {
	margin-bottom: 8px;

	@include mq($from: lg) {
		margin-left: var(--grid-gap);
		margin-right: var(--grid-gap);
	}
}

.newsletter-subscription__label-text {
	display: block; /* label default? */
	margin-bottom: var(--grid-gap);
}

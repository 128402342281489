/* ==========================================================================
   # CONTAINER
   ========================================================================== */

// Map of container widths. The key represents the breakpoint name,
// the value is the width that will be applied at that breakpoint

$container-widths: (
	sm: 576px, // Bootstrap default was 510px
	md: 768px, // Bootstrap default was 690px
	lg: 992px, // Bootstrap default was 930px
	xl: 1500px // Bootstrap default was 1410px
);





.container {
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	padding-left: var(--grid-gap);
	padding-right: var(--grid-gap);
	width: 100%;

	// Generate `max-width`s for each breakpoint using the `$mq-breakpoints` map
	@each $breakpoint-name, $breakpoint-value in $mq-breakpoints {
		@include mq($from: $breakpoint-name) {
			max-width: map-get($container-widths, $breakpoint-name)
		}
	}
}

/* ==========================================================================
# Showcase pages
========================================================================== */

/**
 * namespace for all showcase pages
 */

.page--showroom {

	/* Stage
	   ========================================================================== */

	/**
	 * additions to stage
	 */

	.stage--showcase {
		margin-bottom: 0;

		.stage__intro {
			background-color: $color-white;
			border-radius: 100%;
			height: 200px;
			padding-top: 2em;
			width: 200px;

			@include mq($from: sm) {
				height: 240px;
				width: 240px;
			}

			@include mq($from: lg) {
				height: 340px;
				padding-top: 3em;
				width: 340px;
			}

			.stage__title {
				color: $color-black;
				font-size: var(--font-size-heading-m);
				font-weight: 800;
				letter-spacing: normal;
				line-height: 1.2;
				padding: 0 10%;

				@include mq($from: sm) {
					font-size: var(--font-size-heading-l);
					letter-spacing: 2px;
					line-height: 1.75;
					padding: 0 10%;
				}

				&::after {
					line-height: 1;

					@include mq($from: lg) {
						line-height: 1.5;
					}
				}
			}

			.stage__subline {
				color: $color-black;
				font-size: var(--font-size-heading-s);
				line-height: 1.25em;
			}
		}

		@include mq($from: md) {

			.figure__media {
				height: auto;
				width: 100%;
			}
		}
	}

    /* text elements
       ========================================================================== */

	.section__title {
		letter-spacing: normal;
		margin-bottom: 0;
		text-transform: none;
	}

	.section__title--large {
		line-height: 2;

		&::after {
			line-height: 1.25;
		}
	}

	.section__title--xl {
		line-height: 1.75em;

		@include mq($until: xl) {

			&::after {
				line-height: 1.5;
			}
		}
	}

	.section__title--xxl {
		letter-spacing: 0.04em;
		line-height: 1;

		&::after {
			line-height: 3;
		}

		@include mq($from: lg) {

			&::after {
				line-height: 4;
			}
		}
	}

	/**
	 * new handmade copy text classes
	 */

	.text-copy-m {
		font-size: 16px;

		@include mq($from: md) {
			font-size: 18px;
		}

		@include mq($from: xl) {
			font-size: 20px;
		}
	}

	.text-copy-l {
		font-size: 18px;

		@include mq($from: md) {
			font-size: 20px;
		}

		@include mq($from: xl) {
			font-size: 24px;
		}
	}

	.text-copy-xl {
		font-size: 32px;

		@include mq($from: md) {
			font-size: 48px;
		}

		@include mq($from: xl) {
			font-size: 60px;
		}
	}

	.text-copy-xxl {
		font-size: 40px;
		line-height: 1.25;

		@include mq($from: md) {
			font-size: 64px;
		}

		@include mq($from: xl) {
			font-size: 72px;
		}
	}

	/**
	 * quotes on showcase pages
	 * better naming ??
	 */

	blockquote {
		p {
			padding-left: var(--grid-gap);

			@include mq($from: sm) {
				padding-left: 0;
			}
		}
	}

	blockquote .quotation::before {
		content: '„';
		display: block;
		font-size: inherit;
		font-weight: 700;
		line-height: inherit;
		margin-left: -0.5em;
		position: absolute;
	}



	/**
	 * additional spacings
	 * here some additional spacings, with responsive and increased values
	 */

	.spacing-small-bottom {
		padding-bottom: var(--grid-gap);
	}

	.spacing-small-top {
		padding-top: var(--grid-gap);
	}

	.spacing-medium-bottom {
		padding-bottom: calc(#{var(--grid-gap)} * 2);
	}

	.spacing-medium-top {
		padding-top: calc(#{var(--grid-gap)} * 2);
	}

	.spacing-large-bottom {
		padding-bottom: calc(#{var(--grid-gap)} * 3);
	}

	.spacing-large-top {
		padding-top: calc(#{var(--grid-gap)} * 3);
	}

    /* full width teaser
    ========================================================================== */

	/**
	 * modifyer for figure figure--horizontal:
	 * make it work without standard container
	 */

	.figure--fifty-fifty {
		display: -ms-grid;
		display: grid;
		padding: var(--grid-gap);

		@include mq($from: md) {
			padding: 0;
			grid-column-gap: 0;
			/*-ms-grid-columns: (1fr) [2];*/
			grid-template-columns: repeat(2, 1fr);
		}

		.figure__media,
		.figure__list {
			@include mq($from: md) {
				grid-column: 1 / 2;
			}
		}

		.img-mask {
			max-height: 80vw;
			overflow: hidden;

			@include mq($from: md) {
				max-height: 600px;
			}

			@include mq($from: xl) {
				max-height: 740px;
			}
		}

		.figure__caption {
			margin-top: 0;
			align-self: center;
			padding: var(--grid-gap) 0;

			@include mq($from: sm) {
				padding: 4vw;
			}

			@include mq($from: md) {
				align-self: center;
				grid-column: 2 / 3;
			}

			@include mq($from: lg) {
				padding: 8vw auto;
			}

			@include mq($from: xl) {
				padding: 8vw;
			}
		}
	}

    /* images
    ========================================================================== */

	.figure--illu-landscape {
		margin-top: var(--grid-gap);

		@include mq($until: xl) {
			overflow-x: scroll;
			overflow-y: hidden;

			.img {
				max-width: initial;
			}
		}

		@include mq($from: xl) {
			padding: 0 calc(#{var(--grid-gap)} * 2)
		}
	}

	/**
	 * modifyer for img:
	 * stretch img to cover existing space
	 * find a better solution?
	 */

	.img--fullwidth {
		width: 100%;
	}

    /* gallery
    ========================================================================== */

	/**
	 * modifyer for gallery:
	 * image size 1:1 and 2:1 in desktop
	 */

	.gallery {
		margin: 0;

		.ratio::before {
			padding-bottom: 50%;
		}

		@include mq($until: md) {
			.ratio::before {
				padding-bottom: percentage(1/1); // Force square ratio @mobile
			}
		}

		img {
			width: 100vw;
		}

		.flickity-page-dots {
			bottom: 45px;

			@include mq($from: md) {
				bottom: 15px;
			}

			.dot::after {
				background-color: $color-white;
			}
		}

		.flickity-button {
			display: none;
		}
	}

	/* grid
    ========================================================================== */

	/**
	 * modifyer for grid__item:
	 * 50:50, narrow
	 * display reverse option
	 */

	@include mq($from: md) {

		.grid__item--narrow {
			grid-row: 1;

			&:first-child {
				grid-column: 7 / span 6;

				@include mq($from: xl) {
					grid-column: 7 / span 4;
				}
			}

			&:last-child {
				grid-column: 2 / span 5;
			}
		}

		.grid-items--reverse {

			.grid__item--narrow {

				&:first-child {
					grid-column: 1 / span 6;

					@include mq($from: xl) {
						grid-column: 2 / span 4;
					}
				}

				&:last-child {
					grid-column: 7 / span 5;
				}
			}
		}

		.grid-items--reverse {

			.grid__item--narrow {

				&:first-child {
					grid-column: 1 / span 6;

					@include mq($from: xl) {
						grid-column: 2 / span 4;
					}
				}

				&:last-child {
					grid-column: 7 / span 5;
				}
			}
		}

		.items--padding-2 {

			.grid__item {

				&:first-child {
					padding-left: 0;

					@include mq($from: xl) {
						padding-left: calc(#{var(--grid-gap)} * 2);
					}
				}

				&:last-child {
					padding-right: 0;

					@include mq($from: xl) {
						padding-right: calc(#{var(--grid-gap)} * 2);
					}
				}
			}
		}

		.items--padding-3 {

			.grid__item {

				&:first-child {
					padding-left: 0;

					@include mq($from: xl) {
						padding-left: calc(#{var(--grid-gap)} * 3);
					}
				}

				&:last-child {
					padding-right: 0;

					@include mq($from: xl) {
						padding-right: calc(#{var(--grid-gap)} * 3);
					}
				}
			}
		}
	}

	/**
	 * special showcase image positioning
	 * new 25er grid with no grid-gaps
	 * for more flexibility
	 */

	.petersburger-haengung25 {
		padding-left: var(--grid-gap);
		padding-right: var(--grid-gap);

		.grid__item {
			padding-bottom: calc(var(--grid-gap) / 2);
			padding-top: calc(var(--grid-gap) / 2);
		}
	}

	@include mq($from: sm) {
		.petersburger-haengung25 {
			display: grid;
			grid-column-gap: 0;
			grid-row-gap: var(--grid-gap);
			grid-template-columns: repeat(25, 1fr);
			padding-left: 0;
			padding-right: 0;
			width: 100%;

			.grid__item {
				padding-bottom: 0;
				padding-top: 0;
			}
		}
	}
}

/* ==========================================================================
   # INDEX-LIST
   ========================================================================== */

/**
 * Index lists are used to navigate a large list of data like a product list.
 * It helps in making the content visible and navigable and also provides an
 * overview of where the user currently is.
 *
 * 1. Flickity doesn't work with flex so make sure the container is a block
 * 2. Make the margin between inline nav items dynamic so it grows and shrinks
 *     with the available space
 * 3. Make sure nav links are quadratic and text is in the middle
 */

[data-index-list] {

	@include mq($until: xl) {
		@include enable-flickity;
	}

	&.flickity-enabled {
		display: block; /* [1] */
	}



	&.nav--inline {

		.nav__item + .nav__item {
			margin-left: auto; /* [2] */
		}
	}


	.nav__item {

		&.is-active {

			.nav__link {
				color: $color-white;
				background-color: $color-black;
				border-radius: 50%;
			}
		}

		&.is-disabled {
			color: $color-text-muted;

			.nav__link {
				cursor: default;
			}
		}
	}



	.nav__link {
		font-weight: bold;
		box-sizing: content-box;
		padding: 15px;
		text-align: center; /* [3] */
		line-height: .75em; /* [3] */
		width: .75em; /* [3] */
		height: .75em; /* [3] */
	}
}

/* ==========================================================================
   # NAV
   ========================================================================== */

.nav {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-top: 0;
	margin-bottom: 0;
}


	.nav__item {
		display: block;
		line-height: 1;
	}


	.nav__link {
		color: inherit;
		display: block;
		padding: 20px;
		text-decoration: none;
	}





/* Style variants
	 ========================================================================== */

/**
 * The `.nav--inline` modifier simply displays a nav in one line.
 */

.nav--inline {
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
}


/**
 * The `.nav--overflow` modifier displays a nav in one line and makes it
 * scrollable when there is no room for all items.
 */

.nav--overflow {
	@extend .nav--inline;
	justify-content: start;
	flex-wrap: nowrap;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}


/**
 * The `.nav--spaced` modifier adds margin to subsequent nav items.
 */

.nav--spaced {

	.nav__item + .nav__item {
		margin-left: calc( var(--grid-gap) / 3 );
	}
}


/**
 * The `.nav--dense` modifier makes the spacing of a `.nav__link` smaller.
 */

.nav--dense {
	.nav__link {
		padding-top: 14px;
		padding-bottom: 14px;
	}

	&.nav--inline {
		.nav__link {
			padding-left: 7px;
			padding-right: 7px;
		}
	}
}

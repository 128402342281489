/* ==========================================================================
   # MODAL
   ========================================================================== */

$modal-animation:           $flyout-transition;
$modal-container-max-width: map-get($container-widths, lg);





.modal {
	position: fixed;
	z-index: 2;
	top:    0;
	right:  0;
	bottom: 0;
	left:   0;

	display: none;
	justify-content: center;
	align-items: center;

	&.is-open {
		display: flex;
	}
}





/* Backdrop
   ========================================================================== */

.modal__backdrop {
	background-color: rgba(black, .9);
	position: absolute;
	z-index: -1;
	top:    0;
	bottom: 0;
	right:  0;
	left:   0;
}





/* Container
   ========================================================================== */

.modal__container {
	display: inline-block;
	background-color: white;
	width: 90%;
	max-width: $modal-container-max-width;
	max-height: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	padding: var(--grid-gap) calc(var(--grid-gap) * 2.5) calc(var(--grid-gap) * 1.5);
	box-shadow: 0 2px 4px rgba(black, .5);
}





/* Close Button
   ========================================================================== */

.modal__close {
	position: absolute;
	top: calc( var(--grid-gap) / 2 );
	right: calc( var(--grid-gap) / 2 );

	background: svg-icon('close') center no-repeat;
	background-size: 30px;
	box-sizing: content-box;
	height: 18px;
	width: 18px;
}





/* Title
   ========================================================================== */
.modal__title {
	font-size: var(--font-size-heading-l);
	text-align: center;
	margin-bottom: var(--grid-gap);
}





/* Animation
   ========================================================================== */

@keyframes modalFadeIn {
	from { opacity: 0; }
	  to { opacity: 1; }
}

@keyframes modalFadeOut {
	from { opacity: 1; }
	  to { opacity: 0; }
}

@keyframes modalScaleIn {
	from { opacity: 0; transform: scale(.9); }
	  to { opacity: 1; transform: scale(1); }
}

@keyframes modalScaleOut {
	from { opacity: 1; transform: scale(1); }
	  to { opacity: 0; transform: scale(.9); }
}


// Show
.modal[aria-hidden="false"] {

	.modal__backdrop {
		animation: modalFadeIn $modal-animation forwards;
	}

	.modal__container {
		animation: modalScaleIn $modal-animation forwards;
	}
}


// Hide
.modal[aria-hidden="true"] {

	.modal__backdrop {
		animation: modalFadeOut $modal-animation backwards;
	}

	.modal__container {
		animation: modalScaleOut $modal-animation backwards;
	}
}

/* ==========================================================================
   # GRID
   ========================================================================== */

/**
 * These grid classes are helpers for variants of the standard grid.
 *
 * The typical standard layout grid comes with 12 columns with column gaps in
 * between. This area covers the whole layout width of the actual breakpoint’s
 * layout.
 */


.grid {
	display: grid;
	grid-column-gap: var(--grid-gap);
	grid-template-columns: repeat(12, 1fr);
}




/* Grid elements
   ========================================================================== */


// Grid item

.grid__item {
	grid-column: 1 / 13; // Default
}




/* Size variants
   ========================================================================== */


// Variants for some grid columns

// Triggered whith breakpoint SM and higher
@include mq($from: sm) {

	.grid--1\/2\@sm {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(2, 1fr);

		.grid__item {
			grid-column: auto;
		}
	}

	.grid--1\/3\@sm {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(3, 1fr);

		.grid__item {
			grid-column: auto;
		}
	}

	.grid--1\/3-2\/3\@sm {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(3, 1fr);

		.grid__item {

			&:nth-child(2n) {
				grid-column: 2 / 4;
			}

			&:nth-child(2n+1) {
				grid-column: 1 / 2;
			}
		}
	}

	.grid--2\/3-1\/3\@sm {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(3, 1fr);

		.grid__item {

			&:nth-child(2n) {
				grid-column: 3 / 4;
			}

			&:nth-child(2n+1) {
				grid-column: 1 / 3;
			}
		}
	}

	.grid--1\/4\@sm {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(4, 1fr);

		.grid__item {
			grid-column: auto;
		}
	}
}

// Triggered whith breakpoint MD and higher
@include mq($from: md) {

	.grid--1\/2\@md {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(2, 1fr);

		.grid__item {
			grid-column: auto;
		}
	}

	.grid--1\/3\@md {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(3, 1fr);

		.grid__item {
			grid-column: auto;
		}
	}

	.grid--1\/3-2\/3\@md {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: 1fr 2fr;

		.grid__item {
			grid-column: auto;
		}
	}

	.grid--2\/3-1\/3\@md {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: 2fr 1fr;

		.grid__item {
			grid-column: auto;
		}
	}

	.grid--1\/4\@md {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(4, 1fr);

		.grid__item {
			grid-column: auto;
		}
	}
}

// Triggered whith breakpoint LG and higher
@include mq($from: lg) {

	.grid--1\/2\@lg {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(2, 1fr);

		.grid__item {
			grid-column: auto;
		}
	}

	.grid--1\/3\@lg {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(3, 1fr);

		.grid__item {
			grid-column: auto;
		}
	}

	.grid--1\/3-2\/3\@lg {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(3, 1fr);

		.grid__item {

			&:nth-child(2n) {
				grid-column: 2 / 4;
			}

			&:nth-child(2n+1) {
				grid-column: 1 / 2;
			}
		}
	}

	.grid--2\/3-1\/3\@lg {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(3, 1fr);

		.grid__item {

			&:nth-child(2n) {
				grid-column: 3 / 4;
			}

			&:nth-child(2n+1) {
				grid-column: 1 / 3;
			}
		}
	}

	.grid--1\/4\@lg {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(4, 1fr);

		.grid__item {
			grid-column: auto;
		}
	}
}

// Triggered whith breakpoint XL and higher
@include mq($from: xl) {

	.grid--1\/2\@xl {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(2, 1fr);

		.grid__item {
			grid-column: auto;
		}
	}

	.grid--1\/3\@xl {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(3, 1fr);

		.grid__item {
			grid-column: auto;
		}
	}

	.grid--1\/3-2\/3\@xl {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(3, 1fr);

		.grid__item {

			&:nth-child(2n) {
				grid-column: 2 / 4;
			}

			&:nth-child(2n+1) {
				grid-column: 1 / 2;
			}
		}
	}

	.grid--2\/3-1\/3\@xl {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(3, 1fr);

		.grid__item {

			&:nth-child(2n) {
				grid-column: 3 / 4;
			}

			&:nth-child(2n+1) {
				grid-column: 1 / 3;
			}
		}
	}

	.grid--1\/4\@xl {
		grid-row-gap: var(--grid-gap);
		grid-template-columns: repeat(4, 1fr);

		.grid__item {
			grid-column: auto;
		}
	}
}


// Variants for narrower layouts in greater breakpoints

// Triggered whith breakpoint SM and higher
@include mq($from: sm) {

	.grid--10\/12\@sm {

		.grid__item {
			grid-column: 2 / 12;
		}
	}

	.grid--8\/12\@sm {

		.grid__item {
			grid-column: 3 / 11;
		}
	}

	.grid--6\/12\@sm {

		.grid__item {
			grid-column: 4 / 10;
		}
	}

	.grid--4\/12\@sm {

		.grid__item {
			grid-column: 5 / 9;
		}
	}
}

// Triggered whith breakpoint MD and higher
@include mq($from: md) {

			.grid__item {
				grid-column: 1 / 13; // Default
			}

	.grid--10\/12\@md {

		.grid__item {
			grid-column: 2 / 12;
		}
	}

	.grid--8\/12\@md {

		.grid__item {
			grid-column: 3 / 11;
		}
	}

	.grid--6\/12\@md {

		.grid__item {
			grid-column: 4 / 10;
		}
	}

	.grid--4\/12\@md {

		.grid__item {
			grid-column: 5 / 9;
		}
	}
}

// Triggered whith breakpoint LG and higher
@include mq($from: lg) {

	.grid--10\/12\@lg {

		.grid__item {
			grid-column: 2 / 12;
		}
	}

	.grid--8\/12\@lg {

		.grid__item {
			grid-column: 3 / 11;
		}
	}

	.grid--6\/12\@lg {

		.grid__item {
			grid-column: 4 / 10;
		}
	}

	.grid--4\/12\@lg {

		.grid__item {
			grid-column: 5 / 9;
		}
	}
}

// Triggered whith breakpoint XL and higher
@include mq($from: xl) {

	.grid--10\/12\@xl {

		.grid__item {
			grid-column: 2 / 12;
		}
	}

	.grid--8\/12\@xl {

		.grid__item {
			grid-column: 3 / 11;
		}
	}

	.grid--6\/12\@xl {

		.grid__item {
			grid-column: 4 / 10;
		}
	}

	.grid--4\/12\@xl {

		.grid__item {
			grid-column: 5 / 9;
		}
	}
}

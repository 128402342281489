/* ==========================================================================
   # BREADCRUMB
   ========================================================================== */

/**
 * 1. Centered breadcrumb on product detail page
 * 2. No shrinking of breadcrumb items in overflow navs
 */

.breadcrumb {
	display: flex;
	flex-wrap: nowrap;
	justify-content: left;

	.nav-bar & {

		@include mq($from: md) {
			margin-left: auto; /* [1] */
			margin-right: auto; /* [1] */
		}
	}

	&.nav--overflow {

		.breadcrumb__item:last-child {
			flex-shrink: 0; /* [2] */
		}
	}
}


.breadcrumb__item {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	flex-shrink: 0;
	text-transform: uppercase;
	font-size: 13px;
	letter-spacing: 1px;

	&:last-child {
		flex-shrink: 1;
	}

	&::before {
		content: "〈 ";
		font-size: 11px;
		font-weight: 700;
		margin-right: 4px;
	}

	&:hover {
		color: $color-text;
		text-decoration: none;
	}
}

.breadcrumb__item--base {

	&::before {
		content: "";
	}
}

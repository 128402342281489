/* ==========================================================================
   # LIST
   ========================================================================== */

.list {
	list-style-type: disc;
	padding-left: 20px;
	margin-top: 0;
	margin-bottom: 1rem;
}


/* Style variants
   ========================================================================== */

.list--bare {
	list-style-type: none;
	padding-left: 0;
}

.list--bordered {
	border-top: 1px solid $color-border;

	> .list__item {
		border-bottom: 1px solid $color-border;
	}
}

/* ==========================================================================
   #SPACINGS
   ========================================================================== */

/**
 * Utility classes to put specific spacing values onto elements. The below loop
 * will generate us a suite of classes like:
 *
 *   .margin-top {}
 *   .padding-left-large {}
 *   .margin-right-small {}
 *   .padding {}
 *   .padding-right-none {}
 *   .padding-horizontal {}
 *   .padding-vertical-small {}
 */

$inuit-spacing-directions: (
	null: null,
	"-top": "-top",
	"-right": "-right",
	"-bottom": "-bottom",
	"-left": "-left",
	"-horizontal": "-left" "-right",
	"-vertical": "-top" "-bottom",
) !default;

$inuit-spacing-properties: (
	"padding": "padding",
	"margin": "margin",
) !default;

$inuit-spacing-sizes: (
	null: 20px,
	// "-tiny": 4px,
	"-small": 8px,
	"-large": 30px,
	"-huge": 40px,
	"-none": 0,
	"-auto": auto,
	"-grid-gap": var(--grid-gap)
) !default;

@each $property-namespace, $property in $inuit-spacing-properties {

	@each $direction-namespace, $direction-rules in $inuit-spacing-directions {

		@each $size-namespace, $size in $inuit-spacing-sizes {

			.#{$property-namespace}#{$direction-namespace}#{$size-namespace} {

				@each $direction in $direction-rules {
					#{$property}#{$direction}: $size !important;
				}

			}

		}

	}

}

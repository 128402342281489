/* ==========================================================================
   # BUTTON
   ========================================================================== */

$button-primary-color:                      $color-white;
$button-primary-background-color:           $color-oase-newturqoise;
$button-primary-background-color-hover:     $color-oase-newdarkblue;

$button-secondary-color:                    $color-oase-newdarkblue;
$button-secondary-background-color:         $color-oase-pro-mid-grey;
$button-secondary-background-color-hover:   $color-oase-newturqoise;

$button-outline-color:                      $color-black;
$button-outline-border-width:               1px;
$button-outline-border-color:               $color-gainsboro;
$button-outline-border-color-hover:         $color-black;
$button-outline-background-color-hover:     rgba(white, .3);

$button-padding:                            14px 30px;
$button-small-padding:                      8px 16px 9px;
$button-large-padding:                      18px 40px;





.btn {
	border: none;
	border-radius: 5em;
	display: inline-block;
	background-color: transparent;
	font-size: var(--font-size-text-xs);
    font-weight: 400;
	letter-spacing: 0.125em;
	line-height: 1;
	margin: 0;
	padding: $button-padding;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	vertical-align: middle;
	white-space: nowrap;
	transition: background-color .25s ease-in-out;

	&:hover {
		text-decoration: none;
	}

	// Remove focus ring for mouse users if browser supports `:focus-visible`
	&:focus:not(.focus-visible) {
		outline: none;
	}

	// Button with icon and text label

	> .icon {
		vertical-align: text-bottom;

		+ .btn__label {
			margin-left: .5em;
		}
	}
}





/* Button Elements
   ========================================================================== */

.btn__label {
	max-width: 100%;
	white-space: nowrap;
}





/* Style variants
   ========================================================================== */

.btn--primary {
	color: $button-primary-color;
	background-color: $button-primary-background-color;

	&:hover {
		color: $button-primary-color;
		background-color: $button-primary-background-color-hover;
	}
}


.btn--secondary {
	color: $button-secondary-color;
	background-color: $button-secondary-background-color;

	&:hover {
		color: $button-secondary-color;
		background-color: $button-secondary-background-color-hover;
	}
}


.btn--outline {
	color: $button-outline-color;
	border: $button-outline-border-width solid $button-outline-border-color;
	padding: directions-compute($button-padding, -$button-outline-border-width);

	&:hover {
		color: $button-outline-color;
		background-color: $button-outline-background-color-hover;
		border-color: $button-outline-border-color-hover;
	}
}




/* Button sizes
   ========================================================================== */

.btn--small {
	padding: $button-small-padding;

	&.btn-outline {
		padding: directions-compute($button-small-padding, -$button-outline-border-width);
	}
}

.btn--large {
	padding: $button-large-padding;

	&.btn-outline {
		padding: directions-compute($button-large-padding, -$button-outline-border-width);
	}
}





/* Button modifiers
   ========================================================================== */

.btn--full {
	display: block;
	width: 100%;
}


.btn--square {
	border-radius: 0;
}


.btn--icon {
	padding: 16px;
}


.btn--floating {
	position: absolute;
	top: 0;
	left: 0;
}

/* ==========================================================================
   # HEADER
   ========================================================================== */

$header-color:                    $color-white;
$header-color-muted:              $color-oase-newturqoise;
$header-color-background:         $color-oase-newdarkblue;
$header-color-border:             $color-oase-newdarkblue;
$header-nav-item-color:           $color-oase-pro-mid-grey;
$header-nav-item-hover-color:     $color-oase-pro-light-grey;
$header-breakpoint-phone:         sm;
$header-breakpoint-tablet:        md;
$header-breakpoint-desktop:       lg;
$header-breakpoint-desktop-large: xl;
$header-transition:               .35s cubic-bezier(0.25, 0.46, 0.45, 0.94);





.header {
	height: var(--header-height);
	position: fixed;
	width: 100%;
	top: 0;
	color: $header-color;
	background-color: $header-color-background;
	border-bottom: 1px solid $header-color-border;
	box-sizing: content-box;
	z-index: 2;
}






/* Row
   ========================================================================== */

.header__row {
	display: flex;
	justify-content: space-between;
	height: inherit;
	margin-left: 5px;
	margin-right: 5px;

	@include mq($from: $header-breakpoint-tablet) {
		margin-left: 20px;
		margin-right: 20px;
	}

	@include mq($from: $header-breakpoint-desktop-large) {
		margin-left: 40px;
		margin-right: 40px;
	}
}





/* Logo
   ========================================================================== */

.header__logo {
	position: absolute;
	z-index: 1;
	left: 78px;
	top: 50%;
	transform: translate(0, -20px);
	width: 92px;

	@include mq($until: $header-breakpoint-tablet) {
		clip: rect(0, 92px, 30px, 0);
	}

	@include mq($from: $header-breakpoint-tablet) {
		position: relative;
		left: auto;
		top: auto;
        margin-bottom: 5px;
        margin-top: 4px;
		margin-left: 20px;
		transform: none;
		width: 100px;
	}

	@include mq($from: $header-breakpoint-desktop-large) {
		margin-left: 40px;
	}
}





/* Toggle
   ========================================================================== */

.header__toggle {
	background-position: center;
	background-repeat: no-repeat;
	box-sizing: content-box;
	height: 20px;
	width: 30px;
	position: relative;
	z-index: 1;
	padding-left: 20px;
	padding-right: 20px;

	@include mq($from: $header-breakpoint-tablet) {
		z-index: 0;
	}

	&.is-open {
		z-index: 2;
	}
}


.header__toggle--menu {
	background-image: svg-icon('menu', white);

	&.is-open {
		background-image: svg-icon('close', white);
	}
}


.header__toggle--search {
	background-image: svg-icon('search', white);
	width: 2px;

	@include mq($from: $header-breakpoint-tablet) {
		width: 22px;
	}

	&.is-open {
		background-image: svg-icon('close', white);
	}
}

.header__toggle--flag {
	background-image: svg-icon('flag', white);
	width: 2px;

	@include mq($from: $header-breakpoint-tablet) {
		width: 22px;
	}
}

/* Dealer Finder / to be reworked if this navigation version will remain
   ========================================================================== */
.header__dealer-link {
	background-image: svg-icon('map-marker', white);
	background-position: center;
	background-repeat: no-repeat;
	box-sizing: content-box;
	height: 20px;
	width: 2px;
	position: relative;
	z-index: 0;
	padding-left: 20px;
	padding-right: 20px;

	@include mq($from: $header-breakpoint-tablet) {
		width: 16px;
	}
}

/* Quick-nav
   ========================================================================== */

.header__quick-nav {
	color: $color-oase-pro-mid-grey;
	text-transform: uppercase;
	letter-spacing: 0.125em;

	@include mq($until: $header-breakpoint-desktop) {
		display: none;
	}

	@include mq($from: $header-breakpoint-desktop-large) {
		position: absolute;
		left: 50%;
		height: 100%;
		transform: translateX(-50%);
	}

	.nav__item {
        opacity: 0.6;

		&:hover {
			color: $header-color-muted;
            opacity: 1;
		}

		&.is-active {
			color: $header-color;
            opacity: 1;
		}
	}

	.nav__link {
		padding-left: 20px;
		padding-right: 20px;
	}
}

/* Top100-Logo
   ========================================================================== */
/*.header__top100-nav {
	display: none;

	@include mq($from: $header-breakpoint-tablet) {
		display: inline-block;
		right: 225px;
		top: 50%;
		position: absolute;
		transform: translateY(-50%);
	}

	@include mq($from: $header-breakpoint-desktop) {
		right: 215px;
	}

	@include mq($from: $header-breakpoint-desktop-large) {
		right: 260px;
	}

	.header__top100-icon {

		@include mq($from: $header-breakpoint-tablet) {
			height: 45px;
			width: 30px;
		}
	}
}*/


/* Flyout
   ========================================================================== */

.header__flyout {
	height: calc( var(--window-height) - var(--header-height) );

	@include mq($from: $header-breakpoint-tablet) {
		top: 0;
		height: var(--window-height);
	}
}

.header__flyout--overlay {
	top: 0;
	height: var(--window-height);
	z-index: 2;
}





/* Navigation
   ========================================================================== */

.header__nav {
	font-size: 18px;
    font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 0.125em;

	position: relative;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	background-color: $header-color-background;
	box-sizing: border-box;
	width: 100%;
	height: calc( var(--window-height) - var(--header-height) );
	padding: 85px 20px 0;
	box-shadow: 0 8px 8px 0 rgba(black, .25);
	transition: transform $header-transition, visibility $header-transition;

	@include mq($from: $header-breakpoint-tablet) {
		top: 0;
		overflow: visible;
		width: 33.4vw;
		height: 100%;
		padding-top: calc(var(--header-height) + 27px);
		padding-bottom: 30px;
		padding-right: 15px;

		&::after {
			content: "";
			position: absolute;
			z-index: -1;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: inherit;
			border-right: 1px solid $header-color-border;
		}
	}

	@include mq($from: $header-breakpoint-desktop) {
		width: min-content;
		padding-left: 40px;
		padding-right: 40px;
	}

    /* navigation "headline" first level WT / FT */
    > .nav__item {
        &.is-disabled:nth-child(1) {
            .nav__link {
                padding-top: 0.3125em;
            }
        }
    }

	.header__flyout.is-opening > &,
	.header__flyout.is-open > & {
		transform: translate3D(0, 0, 0);
	}

	&,
	.header__flyout.is-closing > & {
		transform: translate3D(-100%, 0, 0);
	}

	> .nav__item {
		margin-bottom: 2px;

		&:hover,
		&.is-open {

			> .nav__link {
				font-weight: 400;
                letter-spacing: 0.1225em;
			}

			@include mq($from: $header-breakpoint-tablet, $until: $header-breakpoint-desktop-large) {
				>.header__nav {
					width: calc(33.33vw - 35px);
				}
			}

		}

		> .nav__link {

			@include mq($from: $header-breakpoint-desktop) {
				white-space: nowrap;
			}

			// Only matches if `.nav__link` is not the only child of its parent
			&:not(:only-child)::after {
				font-size: 90%;
				content: "〉";
				margin-left: 12px;
			}

			.nav__link-icon {
				margin: -3px 11px 0 0;
			}
		}

		@include mq($until: $header-breakpoint-phone) {
			&:last-child {
				margin-bottom: 0;
				padding-bottom: 28px;
			}
		}

		.nav__item {
			.nav__link {
				color: $header-nav-item-color;
				font-size: 15px;
				letter-spacing: .075em;
				text-transform: none;


				&:hover {
					color: $header-nav-item-hover-color;
				}
			}

			&.is-open {

				.nav__link {
					color: $header-nav-item-hover-color;
				}
			}
		}

        &.is-disabled {

            .nav__link {
                color: $color-oase-light-grey;
                font-weight: 300    ;
                letter-spacing: .1em;
                text-transform: uppercase;

                &:hover {
                    color: $color-oase-light-grey;
                }
            }
        }
	}

	.nav__item--small {
		font-size: 13px;
		margin-bottom: 7px;

		@include mq($from: $header-breakpoint-desktop) {
			margin-bottom: 5px;
		}
	}
}


/**
 * 1. Fix `width: min-content` bug in Safari
 */
.header__nav-level {
	font-size: 14px;
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	padding-top: 0;

	@include mq($from: $header-breakpoint-tablet) {
		box-sizing: content-box; /* [1] */
		left: 100%;
		padding-top: calc(var(--header-height) + 27px);
		z-index: -1;
	}

	.nav__item.is-open > & {
		visibility: visible;
		transform: translate3D(0, 0, 0);

		// Make sure all menu levels close when closing the menu
		.header__flyout:not(.is-open) &,
		.header__flyout.is-closing & {
			transform: translate3D(-100%, 0, 0);
		}
	}

	.nav__item {
		font-weight: normal;
		line-height: 1.25;
		margin-bottom: 5px;

		@include mq($from: $header-breakpoint-desktop) {
			margin-bottom: 0;
		}
	}

	.header__btn {
		margin-bottom: 28px;

		@include mq($from: $header-breakpoint-tablet) {
			margin-bottom: 30px;
		}

		@include mq($from: $header-breakpoint-desktop) {
			margin-bottom: 34px;
		}
	}
}





/* Breadcrumb
   ========================================================================== */

.header__breadcrumb {
	background: $header-color-background;

	margin-bottom: 25px !important;
	padding: 8px 15px;
	position: sticky;
	top: 0;

	@include mq($from: $header-breakpoint-tablet) {
		display: none;
	}

	.breadcrumb__item {
		margin-bottom: 0;

		&.is-current {
			color: $header-color-muted;
		}
	}
}





/* Search
   ========================================================================== */

.header__search {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	right: 0;
	background: $header-color-background;
	overflow: hidden;
	transition: transform $header-transition;

	.is-opening > &,
	.is-open > & {
		transform: translateY(0)
	}

	&,
	.is-closing > & {
		transform: translateY(-100%)
	}
}


.header__search-form {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	height: var(--header-height);

	.form__combined {

		.form__input {
			padding-right: 21px;
		}
	}
}


/**
 * TODO: Extract reset into form component
 */
.header__search-input {
	width: 52vw;
	min-width: 200px;
	max-width: 600px;
	position: relative;
}


@keyframes header-search-loading {
	to { transform: rotate(360deg) }
}

.header__search-loading {
	width: 20px;
	height: 20px;
	background-color: currentColor;
	margin: 50px auto;
	animation: header-search-loading 1s infinite;
	border-radius: 10px;
	border-top-right-radius: 0;
}


.header__search-results {
	width: 52%;
	min-width: 240px;
	max-width: 600px;
	margin: 50px auto;
}


.header__btn {
	margin: 0 20px 26px;
	font-size: 13px;

	@include mq($from: $header-breakpoint-tablet) {
		margin-bottom: 26px;
	}
}





/* Market Selection
   ========================================================================== */

.header__markets {
	display: flex;
	flex-direction: column;
	position: absolute;
	z-index: 2;
	top: 0;
	right: 0;
	overflow: hidden;
	max-height: var(--window-height);
	background-color: $header-color-background;
	box-shadow: 0 40px 60px -20px rgba(black, .25);
	transition: transform $header-transition;

	@include mq($until: sm) {
		width: 100%;
	}

	.is-opening &,
	.is-open & {
		transform: translateX(0);
	}

	&,
	.is-closing & {
		transform: translateX(100%);
	}

	.nav__item {
		padding: 5px 0 5px 15px;
		display: flex;
		flex: 0 0 auto;
		flex-wrap: wrap;
		align-items: center;
		border-bottom: 1px solid $header-color-border;

		@include mq($from: sm) {
			padding-top: 13px;
			padding-bottom: 13px;
		}

		&:last-child {
			border-bottom: 0 none;
		}

		> * {
			margin-right: 15px;
		}
	}

	.nav__link {
		color: $header-nav-item-color;
		padding: 15px 0;

		&:hover {
			color: $header-nav-item-hover-color;
			/*text-decoration: underline;*/
		}
	}
}

.header__markets__close {
    color: $header-nav-item-color;
	display: flex;
	justify-content: center;
	align-items: center;
	height: var(--header-height);
	text-transform: uppercase;
	border-bottom: 1px solid $header-color-border;
	margin: 0 15px;
	padding: 0;
	border-radius: 0;
}

.header__markets__scroll-container {
	max-height: calc( var(--window-height) - var(--header-height) );
	overflow-y: auto;
	padding: 0 15px;
}

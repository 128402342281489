// VARIABLES
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


// Color system
//
// Provides a set of standard colors


// Gray Tones

$color-white:           #ffffff !default;
$color-alabaster:       #f9f9f9 !default;
$color-seashell:        #f1f1f1 !default;
$color-gainsboro:       #dcdcdc !default;
$color-dusty-gray:      #979797 !default;
$color-tundora:         #494949 !default;
$color-black:           #040404 !default;


// Brand Colors

$color-pacific-blue:    #01a4c0 !default;

// new brand colors 2021
$color-oase-darkblue: #00548E;
$color-oase-lightblue: #E0F1F5;
$color-oase-turqoise: #00A3BB;
$color-oase-newdarkblue: #092A45;
$color-oase-newturqoise: #00A9C1;

$color-oase-greybox: #f3f3f3; /* not part of brand colors */

$color-oase-light-grey: #9da9b1;
$color-oase-mid-grey: #6d767a;

$color-oase-pro-light-grey: #f0f4f6;
$color-oase-pro-grey: #e1e8ec;
$color-oase-pro-mid-grey: #ccd4d9;

$color-oase-consumer-turqoise-10: #f1f9fb;
$color-oase-consumer-turqoise-20: #e0f1f5;
$color-oase-consumer-turqoise-30: #c5e5ed;


// Technical Colors

//$color-success:       #28a745 !default;
//$color-info:          #17a2b8 !default;
//$color-warning:       #ffc107 !default;
$color-monza:           #e00122 !default;


// Custom Variables
// Matching list, to link color-name-variables to variables which represent the color’s appearance

$color-background:           $color-white;
$color-border:               $color-oase-mid-grey;
$color-highlight:            $color-pacific-blue;
$color-highlight-hover:      lighten($color-pacific-blue, 6%);
$color-text:                 $color-black;
$color-text-muted:           $color-oase-light-grey;
$color-text-inverted:        $color-white;
$color-warning:              $color-monza;
$color-warning-hover:        lighten($color-monza, 6%);

/* ==========================================================================
   # FIGURE COMPONENT
   ========================================================================== */

/**
 * Xyz…
 */

.figure {
	margin: 0;
	position: relative;
	width: 100%;


	// With optional ratio layer inside

	.ratio__content {
		display: flex;
		justify-content: center;

		.figure__media {
			max-height: 100%;
			object-fit: contain;
		}
	}

	.ratio__content--cover {

		.figure__media {
			max-height: none;
			object-fit: cover;
			width: 100%;
		}
	}
}





/* Feed elements
   ========================================================================== */



// DEPRECATED: From the point of modular use in NEOS it is better to use the "img"-class instead directly
.figure__media {
	@extend .img;
}


// A figure can optionally contain more than one media item

.figure__list {
	@extend .list--bare;
}

.figure__list-item {
	position: relative;
}


// A figure can optionally have a caption text

.figure__caption {
	width: 100%;
}





/* Style variants
   ========================================================================== */


// Horizontal layout of figure and figcaption (ltr)
// DEPRECATED: This component will no longer exist, the element will be build via grid classes

.figure--horizontal {

	.figure__list {
		margin: 0;
	}

	.figure__caption {
		margin-top: var(--grid-gap);
	}

	@include mq($from: lg) {
		display: grid;
		grid-column-gap: var(--grid-gap);
		grid-template-columns: repeat(12, 1fr);

		.figure__media,
		.figure__list {
			grid-column: 1 / 5;
		}

		.figure__caption {
			grid-column: 5 / 13;
			margin-top: 0;
		}
	}
}

/* ==========================================================================
   # nav-bar
   ========================================================================== */

/**
 * horizontal action bar
 * action items muted per default
 */

.nav-bar {
	color: $color-text-muted;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

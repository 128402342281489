/* ==========================================================================
   # FORM
   ========================================================================== */


$form-input-placeholder-color: $color-dusty-gray;





.form {
	/* block indicator only */
}





/* Input
   ========================================================================== */

.form__input {
	appearance: none;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid;
	border-radius: 0;
	color: inherit;
	display: block;
	font: inherit;
	font-size: var(--font-size-text-l);
	line-height: 1.5;
	margin: 0;
	padding: 7.5px 0;
	width: 100%;

	&:focus {
		outline: none;
	}

	&::placeholder {
		color: $form-input-placeholder-color;
	}
}





/* Button
   ========================================================================== */

.form__btn {
	color: inherit;
}





/* Combined
   ========================================================================== */

/**
 * Combined forms look like they are a single element but consist of multiple
 * (mostly two) elements like an input and a button.
 */

.form__combined {
	position: relative;

	.form__input {

		~ .form__btn {
			position: absolute;
			top: 0;
			right: 0;
			background-color: transparent;
			border-width: 0;
			height: 100%;
			padding: 0;
		}

		~ .form__btn--icon {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}

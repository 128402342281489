/* custom styles for "basictemplates" template */
:root {
	--jumbotron-padding-y:3rem;
}

.jumbotron {
	padding-top:var(--jumbotron-padding-y);
	padding-bottom:var(--jumbotron-padding-y);
	margin-bottom:0;
	background-color: transparent;
}

@media (min-width: 768px) {
	.jumbotron {
		padding-top:calc(var(--jumbotron-padding-y) * 2);
		padding-bottom:calc(var(--jumbotron-padding-y) * 2);
	}
}

.jumbotron p:last-child {
	margin-bottom:0;
}

.jumbotron-heading {
    font-weight: 300;
    /*text-transform: uppercase;
    letter-spacing: 0.1em;*/

    h3, h4 {
        font-weight: 300;
    }
}

.jumbotron .container {
	max-width:48rem;

    @media (min-width: 992px) { // lg
        max-width: 75%;
    }
}

.element-description {
	display: block;
	cursor: pointer;
	position: absolute;
	z-index: 10000;
	right: 5px;
	top: 5px;
	background-color: #00ADEE;
	color: #FFFFFF;
	border-radius: 10px;
	width: 20px;
	height: 20px;
	box-shadow: none;
	font-size: 14px;
	text-align: center;
	line-height: 20px;
	font-weight: 400;
}

/* N A V   A D D - O N S  Navbar, shopping cart etc.*/

.navbar-brand {
	display: inline;
	padding: 0;
	margin: 0;
	font-size: inherit;
	line-height: 0.9;
	white-space: normal;
}

.navbar-brand:hover {
	text-decoration: none;
}

.navbar-brand__logo {
	@extend .mx-4;
}

.navbar-brand__title {
	@extend .d-none;
	@extend .d-sm-inline;
}

nav.navbar button {
	border:transparent;
}

.nav-typesize {
	font-size: 1.125rem;
}
@media (min-width: 40em) {
	.nav-typesize {
		font-size: 1.375rem;
	}
}
@media (min-width: 40em) {
	.nav-typesize {
		font-size: 1.75rem;
	}
}

#shopping-cart-layer p {
	padding: 0 0 1rem 0;
	border-bottom: 1px solid rgba(255,255,255,0.3);
}
#shopping-cart-layer p span {
	float: right;
	padding-left: 2rem;
}

.navbar-dark .cart-link {
	font-weight: 500;
	color: white;
}

.navbar-light .cart-link {
	font-weight: 500;
	color: black;
}

/* Featurettes from b4 template */

.featurette-divider {
	margin:5rem 0;
}

.featurette-heading {
	font-weight:300;
	line-height:1;
	font-size:50px;
}

@media (min-width: 62em) {
	.featurette-heading {
		margin-top:7rem;
	}
}

.section--divided {
	position: relative;
	margin-bottom: 7rem;
	padding-bottom: 7rem;

	&:after {
		position: absolute;
		right: ($grid-gutter-width / 2);
		left:  ($grid-gutter-width / 2);
		content: '';
		bottom: 0;

		border-bottom: $hr-border-width solid $hr-border-color;
		height: 1px;
	}


}

/* G R I D - T E A S E R S
   custom styles for grid teasers with fixed aspect ratio for pictures
   regardless of their original proportions.
*/
.container-fluid .row.grid {
	margin-right:0;
	margin-left:0;
}

.teaser-container {
	position:relative;
	padding-top:1.5rem;
	padding-bottom:1.5rem;
	/*  corresponds to py-4 */
}

.teaser-container p {
	font-weight:300;
}

.teaser-container a {
	color:inherit;
	text-decoration:none;
}

.teaser-container a:hover * {
	opacity:.9;
}

.teaser-container.picture-teaser a:hover .pic {
	opacity:.3;
}

.teaser-container .teaser-content h4 {
	padding-top:1rem!important;
}
/* pt-3 */


/*  A S P E C T   R A T I O   P I C T U R E   T E A S E R S  */

[class^="pic-container-"] {
	position:relative;
	width: 100%;
}

.pic-container-4x1 {
	padding-bottom: 25%;
}

.pic-container-3x1 {
	padding-bottom:33.33%;  /* landscape 3 : 1 */
}

.pic-container-23x1 {
	padding-bottom:42.5%; /* cinemascope 2.35 : 1 */
}

.pic-container-2x1 {
	padding-bottom: 50%;
}

.pic-container-16x9 {
	padding-bottom: 56.25%;
}

.pic-container-3x2 {
	padding-bottom: 66%;
}

.pic-container-4x3 {
	padding-bottom: 75%;
}

.pic-container-1x1 {
	padding-bottom: 100%;
}

.pic-container-4x5 {
	padding-bottom: 120%;
}

.pic-container-3x4 {
	padding-bottom: 133%;
}

.pic-container-2x3 {
	padding-bottom: 150%;
}

.pic-container-1x2 {
	padding-bottom: 200%;
}

.pic-container-1x3 {
	padding-bottom: 300%;
}


/* M O R E   A S P E C T   R A T I O S   G O   H E R E  */

/* .pic background-image url inline in page */
.pic {
	position: absolute;
	width: 100%;
	height: 100%;
	background: black center center;
	background-size: cover;
	overflow: hidden;
}

.segment-teaser {

    h4 {
        text-shadow: 0 3px 60px black;
    }

    &.teaser-container.picture-teaser a:hover .pic {
        opacity: .1;
    }

    &.picture-teaser div[class^=pic-container] {
        background-color:#092A45;
    }
}

/* background-behaviour for video elements */

.pic video {
	position: absolute;
	min-width: 100%;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@media (max-width: 840px) {
	.pic video {
		min-height: 100%;
		min-width: auto;
	}
}

/* closed teaser rows (no gaps) */
.picture-teaser.picture-teaser {
	padding:0;
}

.picture-teaser .teaser-content {
	position:absolute;
	bottom:10%;
	left:10%;
	width:80%;
}

.picture-teaser .teaser-content p {
	font-weight: 400;
}

.picture-teaser .pic {
    opacity: .75;

    // in case of full opacity teaser images without text
    &--full-opacity {
        opacity: 1;
    }
}

.picture-teaser .teaser-content * {
	color:#fff!important;
}

.picture-teaser div[class^=pic-container] {
	background-color:#000;
}

.extra-feature {
	color: #6d767a;
}

/* end teaser section */


/* long copy p adaption */
p,
li.nav-item .nav-link,
.featurette p.lead {
	font-weight:300;
	letter-spacing:.025em;
}

.long-copy p,
p.custom-long-copy {
	font-size:110%;
	line-height:1.85;
	color:#555;
}

/**
 * Long copy formats
 */
.long-copy {

	// h1 same style as jumbotron headline
	h1 {
		font-weight: 300;
		margin: 6rem 0; // like calc(var(--jumbotron-padding-y) * 2) for --jumbotron-padding-y = 3rem
	}
}


/* form and cart-related */
/* inkl. form-validation.css by bs4 */

@media (min-width: 768px) {
	.container.cart {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container.cart {
		max-width: 1160px;
	}
}

.btn-round {
	border-radius:16px!important;
	min-width:32px!important;
	margin:.25rem .25rem 0 0;
}

.border-top {
	border-top:1px solid #e5e5e5;
}

.border-bottom {
	border-bottom:1px solid #e5e5e5;
}

.border-top-gray {
	border-top-color:#adb5bd;
}

.box-shadow {
	box-shadow:0 .25rem .75rem rgba(0,0,0,.05);
}

.cart-list .teaser-container .btn-group input {
	width: 30% !important;
}
.cart-list .teaser-container h5.item-price {
	text-align: right;
	font-weight: 400;
	margin-bottom: .7em;
}
.lh-condensed {
	line-height:1.25;
}

.custom-select {
	/* wy-edit sollte eigentlich in bootstrap.css drin sein, fehlt */
	-webkit-appearance:none;
	-moz-appearance:none;
	appearance:none;
}

/* list accordeon (card) modifyers */
.card-header {
	padding:1.75rem 1.5rem;
	background-color:#fff;
	border-bottom:none;
}

.card h5 {
	font-weight: 300;
	color: #666;
	letter-spacing: .015rem;
}

.card h5 a {
	text-decoration: none;
	font-weight: 500;
	color: black;
	letter-spacing: 0;
}

.card h5 a:hover {
	color: #007bff; /* primary zu fuss */
}

.list-icon-arrow h5 {
	max-width: 90%;
}

.list-icon-arrow {
	//background: url("../Images/icons/arrow-right-black.svg") no-repeat 95% center;
	background-size: 15px 40px;
}


// wy-edit
.display-5 {
	font-size: $display5-size;
	font-weight: $display5-weight;
	line-height: $display-line-height;
}

/* display headline sizes unusable on small mobile devices */
@media (max-width: 22em) {
	.display-4 {
		font-size: 2.5rem;
	}
}

/**
 * from head styles ...
 */

@media (min-width: 992px) {
    .row-rev div.row {
        flex-direction: row-reverse;
    }
}

/* ==========================================================================
   # FONTS
   ========================================================================== */

/**
 * mapping the existing Branding fonts to css font weights:
 *
 * Branding-Thin        100
 * Branding-Light       200
 * Branding-Semilight   300
 * Branding-Medium      400, 500
 * Branding-Semibold    600
 * Branding-Bold        700, 800
 * Branding-Black       900
 *
 */

$font-path: "../assets/fonts";

// @import url("//hello.myfonts.net/count/3edc23");

@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingThin/font.woff2') format('woff2'),
         url('#{$font-path}/BrandingThin/font.woff') format('woff');
    font-weight: 100;
}
@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingThinItalic/font.woff2') format('woff2'),
    url('#{$font-path}/BrandingThinItalic/font.woff') format('woff');
    font-style: italic;
    font-weight: 100;
}




@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingLight/font.woff2') format('woff2'),
    url('#{$font-path}/BrandingLight/font.woff') format('woff');
    font-weight: 200;
}
@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingLightItalic/font.woff2') format('woff2'),
    url('#{$font-path}/BrandingLightItalic/font.woff') format('woff');
    font-style: italic;
    font-weight: 200;
}



@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingSemilight/font.woff2') format('woff2'),
    url('#{$font-path}/BrandingSemilight/font.woff') format('woff');
    font-weight: 300;
}
@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingSemilightItalic/font.woff2') format('woff2'),
    url('#{$font-path}/BrandingSemilightItalic/font.woff') format('woff');
    font-style: italic;
    font-weight: 300;
}




@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingMedium/font.woff2') format('woff2'),
         url('#{$font-path}/BrandingMedium/font.woff') format('woff');
    font-weight: 400;
}
@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingMediumItalic/font.woff2') format('woff2'),
         url('#{$font-path}/BrandingMediumItalic/font.woff') format('woff');
    font-style: italic;
    font-weight: 400;
}
@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingMedium/font.woff2') format('woff2'),
    url('#{$font-path}/BrandingMedium/font.woff') format('woff');
    font-weight: 500;
}
@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingMediumItalic/font.woff2') format('woff2'),
    url('#{$font-path}/BrandingMediumItalic/font.woff') format('woff');
    font-style: italic;
    font-weight: 500;
}



@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingSemibold/font.woff2') format('woff2'),
    url('#{$font-path}/BrandingSemibold/font.woff') format('woff');
    font-weight: 600;
}
@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingSemiboldItalic/font.woff2') format('woff2'),
    url('#{$font-path}/BrandingSemiboldItalic/font.woff') format('woff');
    font-style: italic;
    font-weight: 600;
}




@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingBold/font.woff2') format('woff2'),
         url('#{$font-path}/BrandingBold/font.woff') format('woff');
    font-weight: 700;
}
@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingBoldItalic/font.woff2') format('woff2'),
    url('#{$font-path}/BrandingBoldItalic/font.woff') format('woff');
    font-style: italic;
    font-weight: 700;
}
@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingBold/font.woff2') format('woff2'),
    url('#{$font-path}/BrandingBold/font.woff') format('woff');
    font-weight: 800;
}
@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingBoldItalic/font.woff2') format('woff2'),
    url('#{$font-path}/BrandingBoldItalic/font.woff') format('woff');
    font-style: italic;
    font-weight: 800;
}



@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingBlack/font.woff2') format('woff2'),
    url('#{$font-path}/BrandingBlack/font.woff') format('woff');
    font-weight: 900;
}
@font-face {
    font-family: "Branding";
    src: url('#{$font-path}/BrandingBlackItalic/font.woff2') format('woff2'),
    url('#{$font-path}/BrandingBlackItalic/font.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

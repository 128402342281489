:root {
	--header-height: 60px;

	@include mq($from: md) {
		--header-height: 100px;
	}

	/**
	 * Add all breakpoints as CSS custom properties so they can be picked up
	 * by JavaScript using `getPropertyValue` on `documentElement`
	 */
	@each $breakpoint-name, $breakpoint-value in $mq-breakpoints {
		--breakpoint-#{$breakpoint-name}: #{$breakpoint-value};
	}

	/**
	 * Add CSS custom properties for some variables and overwrite them for each
	 * breakpoint allowing us to set the properties just once on an element and
	 * have the values change automatically with each breakpoint
	 */

	// Do the above for `--grid-gap`
	@each $breakpoint-name, $breakpoint-value in $mq-breakpoints {
		@include mq($from: $breakpoint-name) {
			--grid-gap: #{map-get($grid-gaps, $breakpoint-name)};
		}
	}

	// Do the above for `--font-size-heading-*` and `--font-size-display-*`
	@each $breakpoint-name, $breakpoint-value in $mq-breakpoints {
		@include mq($from: $breakpoint-name) {
			@each $size-name, $size-value in $font-sizes-responsive {
				--#{$size-name}: #{$size-value * map-get($font-sizing-factor, $breakpoint-name)};
			}
		}
	}

	// Add fixed font-sizes (non-responsive) as custom properties for completeness
	@each $size-name, $size-value in $font-sizes {
		--#{$size-name}: #{$size-value};
	}
}

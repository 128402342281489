/* ==========================================================================
   # SECTIONS
   ========================================================================== */

/**
 * The MAIN body area can be divided into sections
 * Sections usually have a title (aka headline)
 */


.section {
	display: flex;
	flex-direction: column;
}





/* Section elements
   ========================================================================== */

.section__inner {
	@extend .container;
	padding-bottom: var(--grid-gap);
	padding-top: var(--grid-gap);


	/**
	* 1. Negate container spacing and add on element to make it scroll the whole
	*     horizontal width.
	* 2. Inherit parent padding on last `.nav__item` because of a "bug" where
	*     parent padding on overflowing content gets ignored by browsers:
	*     https://www.brunildo.org/test/overscrollback.html
	*/

	> .nav--overflow {
		@include mq($until: md) {

			&:first-child {
				margin-left: calc( var(--grid-gap) / -1 ); /* [1] */

				.nav__item:first-child {
					padding-left: var(--grid-gap);
				}
			}

			&:last-child {
				margin-right: calc( var(--grid-gap) / -1 ); /* [1] */
			}

			.nav__item:last-child {
				padding-right: var(--grid-gap); /* [2] */
			}
		}
	}
}



.section__title {
	font-size: var(--font-size-text-l);
	letter-spacing: .125em;
	margin-top: 0;
	margin-bottom: var(--grid-gap);
	text-align: center;
	/*text-transform: uppercase;*/

	&::after {
        content: "\a––––––– ";
        -webkit-font-smoothing: antialiased;
        white-space: pre-wrap;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.15em;
        line-height: 2;
        vertical-align: text-top;
	}

}

.section__title--large {
	font-size: var(--font-size-heading-l);
	line-height: 1.58;
}

.section__title--xl {
	font-size: var(--font-size-heading-xl);
}

.section__title--xxl {
	font-size: var(--font-size-display-l);
}



.section__content {
	display: flex;
	flex-direction: column;

	&:not(:only-child) {
		padding-bottom: calc(#{var(--grid-gap)} / 2);
		padding-top: calc(#{var(--grid-gap)} / 2);
	}
}





/* Size variants
   ========================================================================== */


// Variants for narrower layouts in greater breakpoints

.section__inner--narrow-s {

	@include mq($from: md) {
		display: grid;
		grid-column-gap: var(--grid-gap);
		grid-template-columns: repeat(12, 1fr);

		> .section__title,
		> .section__content {
			grid-column: 2 / 12;
		}
	}
}

.section__inner--narrow-xs {

	@include mq($from: md) {
		display: grid;
		grid-column-gap: var(--grid-gap);
		grid-template-columns: repeat(12, 1fr);

		> .section__title,
		> .section__content {
			grid-column: 3 / 11;
		}
	}
}

.section__inner--narrow-xxs {

	@include mq($from: md) {
		display: grid;
		grid-column-gap: var(--grid-gap);
		grid-template-columns: repeat(12, 1fr);

		> .section__title,
		> .section__content {
			grid-column: 4 / 10;
		}
	}
}

// footer

.section__inner--dense {

	@include mq($from: md) {
		padding-bottom: calc(#{var(--grid-gap)} / 2);
		padding-top: calc(#{var(--grid-gap)} / 5);
	}

	@include mq($from: lg) {
		padding-bottom: 3px;
		padding-top: 3px;
	}
}





/* Style variants
   ========================================================================== */


// Variants of the block


.section--preface {

	.section__title {
		font-size: var(--font-size-heading-l);
		letter-spacing: 0;
		margin-bottom: calc(#{var(--grid-gap)} / 2);
		padding-bottom: 0;
		text-transform: none;

		&::after {
			display: none;
		}
	}
}


.section--emphasized {
	background-color: $color-seashell;
	margin-bottom: var(--grid-gap);
}


.section--bordered {
	border-top: 1px solid $color-border;
}


/* Sticky section with border bottom
   ========================================================================== */

.section--sticky {
	position: sticky;
	z-index: 1;
	top: calc( var(--header-height) + 1px ) !important;
	border-bottom: 1px solid $color-border;
	background: $color-white;

	.section__inner {
		padding-bottom: calc( var(--grid-gap) / 2 - 1px );
		padding-top: calc( var(--grid-gap) / 2 - 1px );
	}
}


/* Sticky section darkblue e.g. Breadcrumb Navigation
   ========================================================================== */

.section--darkblue {
    background-color: $color-oase-newdarkblue;
    border-color: $color-oase-newdarkblue;
}


/**
 * oase R0
 * site footer
 *
 */

.footer {

	.nav__link {

		&:hover {
			text-decoration: underline;
		}

		.icon {
			margin-right: 1em;
			margin-top: -4px;
		}
	}
}

// footer section with site links
.footer--site {
	// md and up uses standard – so its not mobile first
	@include mq($until: md) {
		padding-bottom: calc(#{var(--grid-gap)} * 2);
		padding-top: calc(#{var(--grid-gap)} * 2);
	}
}

.footer__site-nav {
	@include mq($from: md) {
		display: grid;
		grid-column-gap: var(--grid-gap);
		grid-template-columns: repeat(4, 1fr);

		/* possible fallback: */
		/*flex-direction: row;
		flex-wrap: wrap;

		.nav__item {
			flex: 1;
		}*/
	}

	.nav__item {
		font-weight: 200;
	}

	.nav__link {
		padding-left: 0;
		padding-right: 0;
	}

	// bordered "accordion" on (small) mobile devices
	@include mq($until: md) {
		border-bottom: 1px solid $color-border;

		> .nav__item {
			border-top: 1px solid $color-border;
			position: relative;
		}

		.nav & {
			border-bottom: 1px solid $color-border;
		}
	}

	.mogdc {
		margin-top: 16px;

		@include mq($from: md) {
			margin-bottom: 0;
		}

		.icon {
			height: 35px;
			width: 180px;

			@include mq($from: md) {
				height: 28px;
				width: 142px;
			}

			@include mq($from: lg) {
				height: 35px;
				width: 180px;
			}
		}
	}

	.footer__top100-nav {
		margin-top: 10px;

		.nav__link {
			/*padding-bottom: 0;*/
			padding-top: 0;
		}

		.icon {
			height: 90px;
			width: 60px;
		}
	}

	.accordion__content--with-logos:last-child {
		@include mq($until: $header-breakpoint-tablet) {
			margin-bottom: 32px;
		}
	}
}

.footer__site-nav__title {
    color: #092a45;
	font-size: inherit;
	font-weight: 700;
	letter-spacing: 0.5px;
	line-height: 20px;
	text-transform: uppercase;
	margin-top: 20px;
	margin-bottom: 20px;
}



.footer__accordion {

	.accordion__trigger {

		@include mq($from: md) {

			&::after {
				content: none;
			}
		}
	}
}



// footer legal section (+ country / lang selection)
.footer--informational {
	flex-direction: row;
	flex-wrap: wrap;
	font-size: var(--font-size-text-xs);
	justify-content: center;
	padding-bottom: 10px;
	text-transform: uppercase;

	@include mq($from: lg) {
		justify-content: space-between;
		margin-left: -20px;
		margin-right: -20px;
		padding-bottom: 0;
	}

	.nav__item {
		white-space: nowrap;

		@include mq($until: md) {
			.nav__link {
				padding: 20px 15px;
			}
		}
	}
}

.footer__language-nav {
	margin-top: 17px;

	@include mq($from: md) {
		margin-top: 0;
	}

	.nav__item:first-child {
		margin-right: 2em;
	}

	label {
		margin-right: 0.5em;
	}
}

/* ==========================================================================
   # BOOTSTRAP OVERRIDES
   ========================================================================== */

/**
 * This file handles all kinds of special overrides needed to make our custom
 * styles work with Bootstrap
 */

//
// Grid
//

.container-fluid {
	padding-left: calc( var(--grid-gap) / 2);
	padding-right: calc( var(--grid-gap) / 2);
}

.row {
	margin-left: calc( var(--grid-gap) / -2);
	margin-right: calc( var(--grid-gap) / -2);
}

[class*="col-"] {
	padding-left: calc( var(--grid-gap) / 2);
	padding-right: calc( var(--grid-gap) / 2);
}



//
// Headings
//

.lead {
	font-size: $lead-font-size;
	font-weight: $lead-font-weight;
}

// Type display classes
.display-1 {
	font-size: $display1-size;
	font-weight: $display1-weight;
	line-height: $display-line-height;
}
.display-2 {
	font-size: $display2-size;
	font-weight: $display2-weight;
	line-height: $display-line-height;
}
.display-3 {
	font-size: $display3-size;
	font-weight: $display3-weight;
	line-height: $display-line-height;
}
.display-4 {
	font-size: $display4-size;
	font-weight: $display4-weight;
	line-height: $display-line-height;
}



//
// Lists
//

.list-unstyled {
	@include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
	@include list-unstyled;
}
.list-inline-item {
	display: inline-block;

	&:not(:last-child) {
		margin-right: $list-inline-padding;
	}
}



// Figure

.figure {
	display: block; // Overwrites bootstraps setting & restores to default
}



//
// Horizontal rules
//

hr {
	margin-top: $hr-margin-y;
	margin-bottom: $hr-margin-y;
	border: 0;
	border-top: $hr-border-width solid $hr-border-color;
}

@media (max-width : 576px) {
    hr.my-5 {
        margin-top: 0 !important;
    }
}

@media (max-width: 767px) {

    .pb-5,
    .py-5 {
        padding-bottom: 0 !important;
    }
}



/**
 * Fix btn focus and restore defaults
 */
.btn:focus {
	box-shadow: none;
	outline: 1px dotted;
	outline: 5px auto -webkit-focus-ring-color;
}

/**
 * Fix header link color
 */
/*.header a:hover {
	color: inherit;
	text-decoration: none;
}*/

/**
 * Fix footer link color
 */
.footer a:hover {
	color: inherit;
}

/**
 * Fix nav link color
 */
a.nav__link {
	color: inherit;
	text-decoration: none;
}

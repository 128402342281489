/* ==========================================================================
   # Format editorial content
   ========================================================================== */

.editorial-content {

	ul {
		@extend .list;
	}
}

/* ==========================================================================
   # IMAGE
   ========================================================================== */

/**
 * Images …
 * 1. Set a maximum relative to the parent
 * 2. Override the height to auto, otherwise images will be stretched when
 *    setting a width and height attribute on the img element.
 */

.img {
	border: 0;
	height: auto; /* [2] */
	max-width: 100%; /* [1] */
}

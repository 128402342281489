/* ==========================================================================
   # SLIDER
   ========================================================================== */

$button-color:       $color-tundora;
$button-color-muted: $color-dusty-gray;


/**
 * Slider for cards (aka teaser-boxes), galleries etc.
 * "Flickity Slider" is used, so here is no BEM country …
 *
 * 1. Flickity doesn't work with flex so make sure the container is a block
 * 2. Parent element has `overflow: hidden` for layout reasons
 */
[data-slider] {

	&.flickity-enabled {
		display: block; /* [1] */

		&.has-pagedots {
			padding-bottom: var(--grid-gap);

			&:not(.is-fullscreen) .flickity-prev-next-button {
				top: calc(50% - (#{var(--grid-gap)} / 2));
			}
		}

		&.is-fullscreen {
			z-index: 2;

			.flickity-button {
				color: white;
			}

			.flickity-fullscreen-button-view {
				display: none;
			}

			.flickity-fullscreen-button-exit {
				display: inline-block;
			}

			.flickity-prev-next-button {
				width: 100px;
				height: 100px;

				.flickity-button-icon {
					stroke-width: 2px;
				}
			}

			.flickity-slider > .figure__media {
				transform: translate(calc(50vw - 50%), calc(50vh - 50%));
				max-width: 100vw;
				max-height: 100vh;
			}

			.flickity-page-dots {

				.dot {
					background-color: transparent;

					&.is-selected::after {
						background-color: white;
					}
				}
			}


			.ratio::before {
				padding-bottom: 100vh;
			}
		}
	}



	.flickity-viewport {
		overflow: visible; /* [2] */

		// Fix to cover the items 'outside' of flickity-viewport
		&::after,
		&::before {
			bottom: 0;
			content: "";
			position: absolute;
			top: 0;
			width: calc(#{var(--grid-gap)} / 2);
		}

		&::before {
			left: calc(#{var(--grid-gap)} / 2 * -1);
		}

		&::after {
			right: calc(#{var(--grid-gap)} / 2 * -1);
		}
	}



	.flickity-button {
		background: none;
		color: $button-color;

		&.flickity-fullscreen-button {
			@extend .btn;
			@extend .btn--icon;

			background-repeat: no-repeat;
			background-position: center;
			box-sizing: content-box;
			height: 18px;
			width: 18px;

			.flickity-button-icon {
				display: none;
			}
		}

		&.flickity-fullscreen-button-view {
			@extend .btn--primary;
			background-image: svg-icon('zoom-in', white);
		}

		&.flickity-fullscreen-button-exit {
			display: none;
			background-image: svg-icon('close', white);
		}
	}

	.flickity-button-icon {
		stroke: currentColor;
		stroke-width: 5px;
		stroke-linecap: round;
		stroke-linejoin: round;
		fill: none;
	}

	.flickity-prev-next-button {
		top: 50%;

		&.previous {
			left: 0;
		}

		&.next {
			right: 0;
		}
	}



	.flickity-page-dots {
		bottom: 0;

		.dot {
			background-color: transparent;
			border-radius: 0;
			height: var(--grid-gap);
			margin: 0 10px;
			max-height: 30px;
			position: relative;
			vertical-align: bottom;
			width: 30px;

			&::after {
				content: "";
				background-color: $button-color-muted;
				height: 2px;
				left: 0;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}

			&.is-selected {

				&::after {
					background-color: $button-color;
				}
			}
		}
	}
}

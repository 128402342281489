/* ==========================================================================
   # CARD
   ========================================================================== */

/**
 * Cards provide a flexible and extensible content container with multiple variants and options, they support a wide variety of content, including images, text, list groups, links, and more.
 * They are typically used for teasers and product teasers.
 */





.card {
	display: flex;
	flex-direction: column;
	padding: calc(#{var(--grid-gap)} / 2);
	position: relative;


	// Product badge

	.badge {
		left: calc(#{var(--grid-gap)} / 2);
		top: calc(#{var(--grid-gap)} / 2);
	}


	// Media (container for image, video etc)

	.figure {
		flex-grow: 1;
		margin-bottom: 1rem;
		order: -1;
	}
}





/* Card elements
   ========================================================================== */


// Headline

.card__title {
	font-size: var(--font-size-text-l);
	margin-bottom: 1rem;
}


// Showroom overline

.card__overline {
	color: $color-highlight;
	font-size: var(--font-size-text-s);
	margin-bottom: .5rem;
	text-transform: uppercase;
}


// Caption

.card__caption {
	margin: 0;

	&:not(:last-child) {
		margin-bottom: 1rem;
	}

	p {
		margin: 0;

		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}

	.table {
		margin: 0;

		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}
}


// Button
// Specific settings for a button on a card
// Typically cards are linked as a whole, so a 'button' is more or less an decorative element resp. a CTA eye catcher

.card__btn {
	@extend .btn--square;
	padding: 12px 40px;
	visibility: hidden;
}


// Link
// Hyperlink wrapper inside a card
// Linked cards are typically teasers, with the whole block beeing linked

.card__link {
	color: currentColor;
	display: flex;
	flex-direction: column;
	margin: calc(#{var(--grid-gap)} / 2 * -1);
	padding: calc(#{var(--grid-gap)} / 2);
	text-decoration: none;

	&:hover {
		color: currentColor;
		text-decoration: none;
	}
}


// Interaction
// Action on elements by interaction with the block

.card:hover .card__btn {
	visibility: visible;
}

.card:hover .card__link {
	box-shadow: 0 5px 25px -5px rgba(0, 0, 0, .25); // @hover: Box gets shadow
	transition: box-shadow 250ms;
}





/* Style variants
   ========================================================================== */


// Card for Products

.card--product {

}


// Card for Product Features

.card--feature {

}


// Card for Families

.card--family {

	.card__title {
		font-size: var(--font-size-heading-s);
		padding-bottom: 1rem;
		position: relative;

		&::after {
			border-bottom: 2px solid;
			bottom: 0;
			content: "";
			left: 0;
			position: absolute;
			width: var(--grid-gap);
		}
	}
}


// Card for simgle hero item (product or family)

.card--hero {
	padding-left: 0;
	padding-right: 0;

	@include mq($from: md) {
		display: grid;
		grid-column-gap: var(--grid-gap);
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: auto 1fr;
		margin-left: auto;
		margin-right: auto;
		padding: 0;

		.card__title {
			font-size: var(--font-size-heading-s);
			grid-column: 2 / 3;
			grid-row: 1 / 2;
			margin-top: 0;
			padding-bottom: 1rem;
			position: relative;

			&::after {
				border-bottom: 2px solid;
				bottom: 0;
				content: "";
				left: 0;
				position: absolute;
				width: var(--grid-gap);
			}
		}

		.figure {
			grid-column: 1 / 2;
			grid-row: 1 / 3;
			margin-bottom: 0;
		}

		.figure__media {
			margin: auto;
		}

		.card__caption {
			grid-column: 2 / 3;
			grid-row: 2 / 3;
		}
	}
}

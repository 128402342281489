/* ==========================================================================
   # COOKIE LAYER
   ========================================================================== */

/**
 * used in combination with page_notice
 *
 */

.cookie-layer {
	background-color: #333333;
	color: $color-white;
	display: flex;
	flex-direction: column;
	margin: 1vw;
	padding: 20px;


	@include mq($from: md) {
		flex-direction: row;
		justify-content: space-between;

		p {
			margin-bottom: 0;
		}
	}

	a {
		color: $color-white;
		text-decoration: underline;
	}

	.cookie-layer__information {
		padding-right: 20px;
	}

	.cookie-layer__accept {
		align-self: center;

		button {
			cursor: pointer;
		}
	}
}

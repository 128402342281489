/* ==========================================================================
   # BADGE
   ========================================================================== */

/**
 * Badges …
 */





/* Badge variables
   ========================================================================== */

$badge-color:                          $color-text;
$badge-background-color:               $color-gainsboro;
$badge-highlighted-color:              $color-text-inverted;
$badge-highlighted-background-color:   $color-highlight;





.badge {
	background-color: $badge-background-color;
	border-radius: 90px;
	color: $badge-color;
	height: 90px;
	left: 0;
	position: absolute;
	top: 0;
	width: 90px;
}





/* Style variants
   ======================================================================= */

.badge--highlighted {
	background-color: $badge-highlighted-background-color;
	color: $badge-highlighted-color;

	// Easteregg…
	&:hover {
		transform: rotate(-3255deg);
		transition-delay: 15s;
		transition-duration: 1.5s;
		transition-property: transform;
		transition-timing-function: cubic-bezier(0.75, 0.5, 0.5, 1.125);
	}
}





/* Badge elements
   ======================================================================= */

.badge__title {
	display: block;
	font-size: var(--font-size-text-s);
	font-weight: bold;
	letter-spacing: 2px;
	position: relative;
	text-align: center;
	text-transform: uppercase;
	top: 50%;
	transform: translateY(-50%);
}

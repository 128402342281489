/* ==========================================================================
   # TEXT / BASIC STYLES
   ========================================================================== */

/**
 * Standard text
 */

 // Deprecate this??? It’s the default anyway ………

//.text {
//	color: inherit;
//	font-family: inherit;
//	font-size: var(--font-size-text-m);
//	font-weight: 400;
//	letter-spacing: normal;
//	//line-height: 1.5; // TODO: Is this an Attribute of "text" or of "P"?
//}

// TODO: EXTEND tags "p", "li", "dt", "dd", th", td", "input", "textarea",
// "button", "select" etc., or APPLY each time …???





/* Style variants
   ========================================================================== */


// Text alignments

.text-center {
	text-align: center !important; // TODO: Is "!important" necessary?
}

.text-left {
	text-align: left !important; // TODO: Is "!important" necessary?
}

.text-right {
	text-align: right !important; // TODO: Is "!important" necessary?
}

.text-right-md-up {
	@include mq($from: md) {
		text-align: right;
	}
}


// Text colors

// muted text / eg in footer (©, labels)
.text-muted {
	color: $color-text-muted;
}

.text-highlight {
	color: $color-highlight;
}

.text-warning {
	color: $color-warning;
}


// Font weights

.text-light {
	font-weight: 200;
}

.text-bold {
	font-weight: 700;
}


// Font/text styles

.text-italic {
	font-style: italic;
}

.text-uppercase {
	text-transform: uppercase;
}


// Font sizes

// Lead-in text
.text-lead-in {
	font-size: var(--font-size-text-l);
}

.text-small-print {
	font-size: var(--font-size-text-s);
}

.text-display-m {
	font-size: var(--font-size-display-m);
	line-height: 1.25;
}

.text-display-l {
	font-size: var(--font-size-display-l);
	line-height: 1.25;
}

.text-display-xl {
	font-size: var(--font-size-display-xxl);
	line-height: 1.25;
}

// Headline decoration for use without cascade

.headline-decorated {
	/*letter-spacing: normal;*/
}

.headline-decorated::after {
    content: "\a––––––– ";
    -webkit-font-smoothing: antialiased;
    white-space: pre-wrap;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.15em;
    line-height: 2;
    vertical-align: text-top;
}

.section--preface .headline-decorated::after {
	display: inline;
}

/* ==========================================================================
   # PAGE
   ========================================================================== */

/**
 * Page component defines basic, outer structure of elements inside the body,
 * such as header, main, footer etc.
 */

/**
 * 1. Make `.page` always fill at least the browser height
 * 2. Let `.page__body` grow, so that `.page__footer` gets pushed down
 */

.page {
	background-color: $color-background;
	color: $color-text;
	display: flex;
	flex-direction: column;
	min-height: 100vh; /* [1] */
}


// Page Header

.page__header {
	height: var(--header-height);
}


// Page Body with Main Content
//

.page__body {
	display: flex;
	flex-direction: column;
	flex-grow: 1; /* [2] */
}


// Page Footer

.page__footer {
	//border-top: 1px solid $color-border;
}


// Page Notice
// Container for Layers of Disclaimer, Error-, Information- and Success-Notifications etc.

.page__notice {

}

.page__notice--sticky {
	bottom: 0;
	position: sticky;
	width: 100%;
	z-index: 1;
}


@function em($pixels, $context: 16px) {
	@if $pixels == 0 {
		@return 0;
	}
	@if type-of($pixels) == list {
		$result: ();

		@each $value in $pixels {
			$result: append($result, em($value));
		}

		@return $result;
	}
	@else {
		@return #{$pixels/$context}em;
	}
}

/* ==========================================================================
   # PRODUCT
   ========================================================================== */

/**
 * "Product" is the container on the product detail page to show all product’s
 * related stuff. For structuring purposes the container ist typically divided
 * in sections.
 */





.product {
	display: flex;
	flex-direction: column;



	// SECTIONS
	// Sections of the "product"-container

	> .section {
		flex-grow: 1;
	}
}





/* Product elements
   ======================================================================= */


// Component: Product title
// Page headline (H1)

.product__title {

}


// Component: Product meta data
// container for chucks of meta data, such as 'product-number', 'availability' etc.

.product__meta-data {
	order: -1;
}


// Component: Product product number
// Product’s product number

.product__number {
	color: $color-text;
	font-size: var(--font-size-text-s);
}


// Component: Product product availability
// Product’s availability

.product__availability {
	color: $color-text-muted;
	font-size: var(--font-size-text-s);

	.product__number + & {
		margin-left: .5em;
	}
}


// Component: Product image
// Images of the product
// Usually placed inside the "stage__media" section

.product__image {
	display: block;
	margin: auto;
	max-width: 100%;
}


// Component: Product price
// The product’s price tag

.product__price {
	color: $color-text;
	font-size: var(--font-size-text-l);
	font-weight: bold;
}


// Component: Product legal-text
// Boring legal stuff … 

.product__price-legal-text {
	color: $color-text-muted;
	font-size: var(--font-size-text-s);
	margin-top: -1em;
}


// Component: Product buttons
// Wrapper for buttons

.product__buttons {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	grid-gap: 1em var(--grid-gap);
	margin-top: var(--grid-gap);

	.btn {
		@extend .btn--large;
		@extend .btn--square;
	}
}

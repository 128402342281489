/* ==========================================================================
   # ACCORDION
   ========================================================================== */

.accordion {
	display: block;
}





/* Trigger
   ========================================================================== */

/**
 * 1. Hide the caret before the summary element
 * 2. Same as above, but for Webkit as it doesn't support the standard way (yet)
 * 3. Same as above, this time for polyfilled details element
 * 4. Force default cursor for `.accordion__trigger` to prevent a text-cursor
 * 5. Remove focus ring for mouse users
 */

.accordion__trigger {
	align-items: center;
	position: relative;
	list-style: none; /* [1] */
	cursor: default; /* [4] */

	&:focus:not(.focus-visible) {
		outline: none; /* [5] */
	}

	&::-webkit-details-marker {
		display: none; /* [2] */
	}

	.no-details .accordion &::before {
		content: none; /* [3] */
	}

	// Custom details marker
	&::after {
		content: "";
		background-image: svg-icon('plus');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 20px;
		position: absolute;
		right: 0;
		top: 0;
		width: 20px;
		height: 100%;

		@include mq($from: xl) {
			width: 30px;
			height: 30px;
			background-size: 30px;
		}

		.accordion[open] > & {
			background-image: svg-icon('minus');
		}
	}
}





/* Title
   ========================================================================== */

.accordion__title {
	font-size: var(--font-size-text-l);
	margin-top: calc( var(--grid-gap) / 2 );
	margin-bottom: calc( var(--grid-gap) / 2 );
	padding-right: 20px;

	@include mq($from: xl) {
		padding-right: 30px;
	}
}





/* Content
   ========================================================================== */

.accordion__content {
	padding-bottom: calc( var(--grid-gap) / 3 );

	:last-child {
		margin-bottom: 0;
	}

	@include mq($from: lg) {
		max-width: 64ch;
		margin-left: auto;
		margin-right: 90px;
	}
}

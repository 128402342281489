/* ==========================================================================
   # STAGE
   ========================================================================== */

.stage {
	@extend .section;
	position: relative;
}





/* Stage elements
   ========================================================================== */

.stage__intro {
	display: flex;
	flex-direction: column;
	min-height: 102px;

	@include mq($from: xl) {
		min-height: 213px;
	}
}



.stage__title {
    color: white;
	margin: auto auto calc( var(--grid-gap) / 2 ) auto;
	text-align: center;

	@include mq($until: xl) {
		margin-top: calc( var(--grid-gap) / 2 );
	}

	&::after {
		content: "\a––––––– ";
		-webkit-font-smoothing: antialiased;
		white-space: pre-wrap;
		font-size: 20px;
        font-weight: 700;
		letter-spacing: -0.15em;
		line-height: 2;
		vertical-align: text-top;
	}
}



.stage__overline {
	text-align: center;
}





/* Style variant: Standard page
   ========================================================================== */

.stage--standard {
	margin-bottom: var(--grid-gap);



	.stage__inner {
		//position: relative;
		bottom: 0;
		display: flex;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}



	.stage__intro {
		color: $color-text-inverted;
		margin: auto;
		min-height: 0;
		padding-top: var(--grid-gap);
		padding-bottom: calc(var(--grid-gap) * 1.5);
		position: relative;

		@include mq($from: lg) {
			max-width: calc(75% - (var(--grid-gap) / 4));
		}

		@include mq($from: xl) {
			max-width:  70%;
		}

		&:not([class*="margin-"]) {
			text-align: center; // Default is centered
		}
	}



	.stage__title {
		font-size: var(--font-size-display-m);
		line-height: 1.2;
		margin: 0;
		text-align: inherit;

		&::after {
			border-bottom: none;
		}
	}



	.stage__subtitle {
		font-size: var(--font-size-heading-m);
	}



	.stage__media {
		background-color: $color-black;
		position: relative;
		z-index: -1;

		.figure {

			.figure__media {
				max-height: none;
				object-fit: cover;
				opacity: .75;
				width: 100%;
			}
		}

		@include mq($until: md) {

			.ratio::before {
				padding-bottom: percentage(1/1); // Force square ratio @mobile
			}
		}
	}

	[data-slider].flickity-enabled.has-pagedots {
		padding-bottom: 0;

		.flickity-page-dots {
			bottom: calc(var(--grid-gap) / 4);

			.dot {

				&::after {
					background-color: $color-text-inverted;
					opacity: .5;
				}

				&.is-selected {

					&::after {
						opacity: 1;
					}
				}
			}
		}
	}
}





/* Style variant: Product Group page
   ========================================================================== */
.stage--group {

    .stage__media {
        background-color: $color-oase-newdarkblue;
        position: relative;
        z-index: -1;

        .figure {

            .figure__media {
                object-fit: cover;
                object-position: 50% 50%;
                width: 100%;
            }

            @include mq($from: lg) {
                padding-bottom: 33.33%;
                overflow: hidden;


                .figure__media {
                    position: absolute;
                    top:    0;
                    bottom: 0;
                    left:   0;
                    right:  0;
                    height: 100%;
                    width:  100%;
                }
            }
        }
    }
}


/* Style variant: Family page
   ========================================================================== */

.stage--family {
	min-height: 50vw;
	overflow: hidden;
	z-index: 0;

	@include mq($from: sm) {
		min-height: 50vw;
	}

	@include mq($from: md) {
		min-height: 40vw;
	}

	@include mq($from: lg) {
		min-height: 30vw;
	}

	@include mq($from: xl) {
		min-height: 20vw;
	}



	.stage__inner {
		display: flex;
		margin: auto;
	}



	.stage__intro {
		background-color: $color-background;
		margin: 0 auto;
		min-height: 0;
		padding: var(--grid-gap);
		width: 76vw;
		z-index: 1;

		@include mq($from: sm) {
			width: 74vw;
		}

		@include mq($from: md) {
			width: 62.5vw;
		}

		@include mq($from: lg) {
			width: 60vw;
		}

		@include mq($from: xl) {
			width: 60vw;
		}
	}



	.stage__title {
        color: $color-oase-newdarkblue;
		font-size: var(--font-size-heading-xl);
		margin-top: 0;
		margin-bottom: 0;
	}



	.stage__overline {
		color: $color-highlight;
		text-transform: uppercase;
	}



	.stage__media {
		height: 50vw;
		margin-bottom: em(-38px);
		order: -1;
		position: relative;

		@include mq($from: sm) {
			margin-bottom: em(-48px);
		}

		@include mq($from: md) {
			margin-bottom: em(-58px);
		}

		@include mq($from: lg) {
			height: 33.333333333vw;
			margin-bottom: em(-68px);
		}

		@include mq($from: xl) {
			margin-bottom: em(-78px);
		}

		&::after {
			background-color: rgba(0, 0, 0, .3);
			bottom: 0;
			content: "";
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
	}



	.figure {
		display: flex;
		height: 100%;
	}

	.figure__media {
		max-height: 100%;
		margin: auto;
	}
}





/* Style variant: Product detail page
   ========================================================================== */

.stage--product {

	.stage__inner {
		@extend .section__inner;
		margin-bottom: var(--grid-gap);
		margin-top: calc(#{var(--grid-gap)} / 2);
		position: relative;

		@include mq($until: lg) {
			display: flex;
			flex-direction: column;
		}

		@include mq($from: lg) {
			display: grid;
			grid-column-gap: var(--grid-gap);
			grid-row-gap: 1em;
			grid-template-columns: repeat(12, 1fr);
			grid-template-rows: auto auto 1fr;
			margin-bottom: 0;
		}
	}



	.stage__intro {
		min-height: 0;

		@include mq($from: lg) {
			grid-column: 8 / 12;
			grid-row: 1 / 2;
		}
	}



	.stage__description {

		@include mq($from: lg) {
			grid-column: 8 / 12;
			grid-row: 2 / 3;
		}
	}



	.stage__media {

		@include mq($until: lg) {
			margin-bottom: calc(#{var(--grid-gap)} * 1.5);
			order: -1
		}

		@include mq($from: lg) {
			grid-column: 2 / 8;
			grid-row: 1 / 4;
			margin-bottom: 0;

			.feed {
				flex-wrap: nowrap;
			}
		}
	}



	.stage__counter {

		@include mq($from: lg) {
			grid-column: 8 / 12;
			grid-row: 3 / 4;
		}
	}



	.stage__badge {
		left: var(--grid-gap);
		top: calc(var(--grid-gap) + 10px);

		@media (min-width: 1500px) {
			left: calc(var(--grid-gap) * 3);
		}
	}
}

/* ==========================================================================
   # TABLE
   ========================================================================== */

.table {
	border-spacing: 0;
	display: table;
	margin: 0;
	width: 100%;
}





/* Table elements
   ========================================================================== */

.table__head {
	display: table-header-group;
}



.table__row {
	display: table-row;
}



.table__th {
	font-weight: bold;
}

.table__th,
.table__td {
	display: table-cell;
	padding-bottom: em(12px);
	padding-left: calc(#{var(--grid-gap)} / 2);
	padding-right: calc(#{var(--grid-gap)} / 2);
	padding-top: em(10px);
	text-align: left;
	vertical-align: top;

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
	}
}



.table__link {
	display: block;
	padding: inherit;
	margin: em(-10px) calc(#{var(--grid-gap)} / -2) em(-12px);
}

.table__th,
.table__td {
	&:first-child .table__link {
		margin-left: 0;
	}

	&:last-child .table__link {
		margin-right: 0;
	}
}



.table__th--middle,
.table__td--middle {
	vertical-align: middle;
}



.table__th--right,
.table__td--right {
	text-align: right;
}



// Table cell, slim as possible

.table__th--narrow,
.table__td--narrow {
	width: 1%;
}





/* Table variants
   ========================================================================== */

/**
 * Bordered table
 * 1. Only apply a top-border to the first row if it is not a `.table__head`
 */

.table--bordered {

	> :first-child:not(.table__head) {
		.table__th,
		.table__td {
			border-top: 1px solid $color-border; /* [1] */
		}
	}

	.table__th,
	.table__td {
		border-bottom: 1px solid $color-border;
	}
}


/**
 * Responsive table
 * Displays table cells below each other when below `md` viewport
 */

.table--responsive {

	@include mq($until: md) {

		.table__head {
			display: none;
		}

		.table__th,
		.table__td {
			display: block;
			padding: 0;
			margin: 0;

			&:first-child {
				padding-top: em(10px);
			}

			&:last-child {
				padding-bottom: em(12px);
			}
		}

		.table__link {
			padding: 0;
			margin: 0;
		}

		.table__th--right,
		.table__td--right {
			text-align: initial;
		}


		.table__th--narrow,
		.table__td--narrow {
			width: auto;
		}



		&.table--bordered {
			border-top: 1px solid $color-border;

			.table__th,
			.table__td {
				border-bottom: 0;

				&:last-child {
					border-bottom: 1px solid $color-border;
				}
			}

			> :first-child:not(.table__head) {
				.table__th,
				.table__td {
					border-top: none;
				}
			}
		}

		.padding-vertical + .padding-vertical {
			padding-top: 0!important;
		}
	}
}





/**
 * Fixed table layout
 * Table with fixed layout causing table and column widths to be set by the
 * widths of table and col elements or by the width of the first row of cells
 */

.table--layout-fixed {
	table-layout: fixed;
}





/* Table sizes
   ========================================================================== */


.table--small {
	font-size: var(--font-size-text-s);


	.table__th,
	.table__td {
		padding-bottom: em(9px);
		padding-left: calc(#{var(--grid-gap)} / 4);
		padding-right: calc(#{var(--grid-gap)} / 4);
		padding-top: em(7px);

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}
}

/* F A C T S   T A B L E / Reference Pages */

.facts-table figure.table table {
    width: 100%;
    /* border-bottom: 1px solid #ccc; */
}

.facts-table figure.table table td {
    border-top: 1px solid #ccc;
    padding: 1em;
    width: 50%;
}

.facts-table figure.table table tr td:first-child {
    color: #888;
}

.facts-table figure.table table tr:first-child td {
    border-top: none;
}

/* ==========================================================================
   # GALLERY SECTION CONTENT
   ========================================================================== */

/**
 * Xyz…
 */

.gallery {
	margin-left: calc(#{var(--grid-gap)} * -1);
	margin-right: calc(#{var(--grid-gap)} * -1);
}


/**
 * Wrapper with background color options
 */

// inner spacings (only with background option)
.background-container {
    padding-top: 5vw;
    padding-bottom: 3vw;
}

.background-container-slim {
    padding-top: 2vw;
    padding-bottom: 1vw;
}

.background-container-none {
    // intentionally left blank
}

// Brand colors (tbd)
.oase-darkblue-bg {
    background-color: $color-oase-darkblue;
    color: $color-white;

    .jumbotron-heading,
    h4 {
        color: #ffffff;
    }
}

.oase-lightblue-bg {
    background-color: $color-oase-lightblue;
}

.oase-turqoise-bg {
    background-color: $color-oase-turqoise;
    color: $color-white;

    .jumbotron-heading,
    .section__title,
    h4 {
        color: #ffffff;
    }
}

.oase-newdarkblue-bg {
    background-color: $color-oase-newdarkblue;
    color: $color-white;

    .jumbotron-heading,
    h4 {
        color: #ffffff;
    }
}

.oase-newturqoise-bg {
    background-color: $color-oase-newturqoise;
    color: $color-white;

    .jumbotron-heading,
    .section__title,
    h4 {
        color: $color-white;
    }
}

.oase-greybox-bg {
    background-color: $color-oase-greybox;
}

// image options
.text-negative,
.text-negative.teaser h2,
.text-negative.teaser h3,
.text-negative.teaser h4,
.text-negative.teaser h5,
.text-negative.teaser h6,
.text-negative.teaser h1::after,
.text-negative.teaser h2::after,
.text-negative.teaser h3::after,
.text-negative.teaser h4::after,
.text-negative.teaser h5::after,
.text-negative.teaser h6::after {
    color: $color-white;
}

.image-bg {
    background-repeat: no-repeat;
    background-size: cover;
}
.image-bg.text-negative {
    background-color: $color-oase-newdarkblue;
    color: $color-white;
}

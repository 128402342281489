/* ==========================================================================
# Images with copyright / credits
========================================================================== */

.copyright {
	position: relative
}

/**
 * only unsplash credit yet
 */

.copyright--unsplash {

	.icon {
		background-image: svg-icon(logo-unsplash,$color-text-muted);
		background-position: top left;
		background-size: contain;
		height: 12px;
		position: relative;
		top: 2px;
		width: 12px;
	}
}

.copyright__badge {
	/*background-color: rgb(240, 240, 240);*/
	background-color: transparent;
	bottom: 0;
	color: $color-text-muted;
	font-size: 11px;
	font-weight: 500;
	left: 0;
	line-height: 1.2;
	padding: 3px 5px;
	position: absolute;

	&:hover {
		color: $color-text-muted;
		text-decoration: none;
	}

	span {
		display: inline-block;
		padding: 2px 2px 1px;
	}
}

/**
 * styles with no actual location
 * should finaly remain empty
 *
 */


// An iframe containing a video (e.g. youtube)

.video {
	height: 100%;
	width: 100%;

	video {
		width: 100%;
	}
}



// @cards & @stage--product

.price {
	font-weight: bold;

	&--outdated {
		color: $color-text-muted;
		text-decoration: line-through;
	}

	&--reduced {
		color: $color-highlight;
		margin-left: .5em;
	}
}


.availability {
	color: $color-text-muted;

	&::before {
		content: "(";
	}

	&::after {
		content: ")";
	}
}



 /**
 * Base Template Buttons
 */

.btn-outline-light {
    @extend .btn--outline;
	border-color: rgba($color-white, .75);
	color: rgba($color-white, .75);
	font-weight: 700;

	&:hover {
		border-color: rgba($color-white, 1);
		color: rgba($color-white, 1);
	}
}

.btn-outline-dark {
	@extend .btn--outline;
	border-color: #494949;
	color: #494949;
	font-weight: 700;

	&:hover {
		background-color: transparent;
		border-color: #040404;
		color: #040404;
	}
}

.btn-dark {
    @extend .btn--primary;
}

.btn-light {
    @extend .btn--secondary;
}




/* ==========================================================================
# BASE TEMPLATES OVERWRITES
========================================================================== */

/**
* Overwrite styles from the base templates
*/

p {
    font-weight: inherit;
    letter-spacing: inherit;
}

/* ==========================================================================
   # FEED
   ========================================================================== */

/**
* Listing of elements, typically cards or images (at a gallery)
* A feed usually contains a number of items
*/


// Component: Feed

.feed {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;

	&[data-slider] {
		flex-wrap: nowrap;
	}
}





/* Feed elements
====================================================================== */


.feed__item {
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	margin: 0;
	width: 100%;

	@include mq($until: sm) {

		@include nth-children('n+2') {
			@include enable-flickity;
		}
	}

	.btn--floating {
		opacity: 0;
	}

	&.is-selected {
        z-index: -1;
        opacity: 1 !important;

		.btn--floating {
			opacity: 1;
			transition-delay: 1s;
			transition-duration: 500ms;
			transition-property: opacity;
			transition-timing-function: ease-in-out;
		}
	}
}





/* Style variants
   ========================================================================== */


// Layout-adaptions for a feed containing cards

.feed--has-cards {
	justify-content: center;
	margin: calc(#{var(--grid-gap)} * -1);
	padding: calc(#{var(--grid-gap)} / 2);
}


// Layout-adaptions for a feed, based on general grid classes

.feed--grid {

	// Go through each breakpoint
	@each $breakpoint-name, $breakpoint-value in $mq-breakpoints {

		// Ignore xs brakpoint
		@if $breakpoint-name != xs {

			// Build classes for two to four items
			@for $i from 2 through 4 {

				&-1\/#{$i}\@#{$breakpoint-name} .feed__item {

					@include mq($from: $breakpoint-name) {
						flex-basis: 100% / $i;
						width: 100% / $i;

						// Disable Flickity per default (mixin needed for specificity)
						@include nth-children('n') {
							@include disable-flickity;
						}

						// Enable Flickity for at least one more item
						@include nth-children('n+#{$i+1}') {
							@include enable-flickity;
						}
					}
				}
			}
		}
	}
}


// Layout adaptions for gallery feeds, usually with figure-elements

.feed--gallery {
	flex-basis: 100%;
	width: 100%;

	.feed__item {
		@include enable-flickity;
	}
}


// Simple vertical listing with borders

.feed--bordered {

	.feed__item {
		border-bottom: 1px solid $color-border;
		padding-bottom: calc(#{var(--grid-gap)} / 4);
		padding-top: calc(#{var(--grid-gap)} / 2);

		&:first-child {
			border-top: 1px solid $color-border;
		}
	}
}

/// Enable flickity
@mixin enable-flickity() {
	&::after {
		content: 'flickity';
		display: none;
	}
}

/// Diable flickity
@mixin disable-flickity() {
	&::after {
		content: '';
	}
}

@charset "UTF-8";

/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
/* wy-edited */
//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import "~bootstrap/scss/functions";
@import "custom-variables";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
//@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot"; // TO BE DONE
//@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images"; // Does no harm
//@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
//@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms"; // Does no harm
//@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions"; // Does no harm!?
//@import "~bootstrap/scss/dropdown"; // Not in Use !!!!?
//@import "~bootstrap/scss/button-group";
//@import "~bootstrap/scss/input-group";
//@import "~bootstrap/scss/custom-forms";
//@import "~bootstrap/scss/nav";
//@import "~bootstrap/scss/navbar";
//@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
//@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/media";
//@import "~bootstrap/scss/list-group";
//@import "~bootstrap/scss/close";
//@import "~bootstrap/scss/modal";
//@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
//@import "~bootstrap/scss/print";

// Base Styles
@import "base-styles";

// Overrides
@import "overrides";

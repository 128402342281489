/* ==========================================================================
   # FAQ
   ========================================================================== */





/* Section
   ========================================================================== */

.faq__section {

	&.is-loading {
		position: relative;
		height: 80vh;
		overflow: hidden;
		background-color: $color-border;

		&::after {
			content: "";
			position: absolute;
			left: 50%;
			top: 50%;
			margin-top: -10px;
			margin-left: -10px;
			width: 20px;
			height: 20px;
			background-color: currentColor;
			animation: header-search-loading 1s infinite;
			border-radius: 10px;
			border-top-right-radius: 0;
		}

		.section__inner {
			opacity: 0;
		}
	}
}





/* Container
   ========================================================================== */

.faq__container {

	@include mq($until: xl) {
		max-width: none;
	}
}




/* faq__btn
   ========================================================================== */

.faq__btn {

	&.has-dropdown {
		$faq-icon-style: (g: ( stroke-width: 3 ));

		background-image: svg-icon('plus', $faq-icon-style);
		background-position: right 16px center;
		background-repeat: no-repeat;
		background-size: 12px;

		&::after {
			content: "";
			display: inline-block;
			width: 20px;
		}

		&.is-open {
			background-image: svg-icon('minus', $faq-icon-style);
		}
	}
}





/* Actions
   ========================================================================== */

.faq__actions {

	// Place a pseudo-element with a gradient to the left of the next element.
	// This will overlap `.faq__actions` and create a fade-out effect
	+ * {

		@include mq($until: $header-breakpoint-tablet) {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				right: 100%;
				top: 0;
				width: calc( var(--grid-gap) / 1.5 );
				height: 100%;
				background-image: linear-gradient(90deg, rgba($color-white, 0), $color-white);
			}
		}
	}

	@include mq($from: $header-breakpoint-tablet) {
		flex-shrink: 0;
		margin-right: 10px;
	}
}





/* Search Form
   ========================================================================== */

/**
 * 1. Make space for icon
 * 2. Place button below input in collapsed state
 */

.faq__search-form {

	.form__input,
	.form__btn {
		color: $color-black;
		border: 1px solid $color-border;
		border-radius: 5em;
	}

	.form__input {
		font-size: var(--font-size-text-m);
		padding: 7px 24px;

		&::placeholder {
			font-size: 12.8px;
			letter-spacing: 1.6px;
			line-height: 2;
			text-transform: uppercase;
		}
	}

	.form__combined {
		position: relative;

		.form__input {
			padding-right: 38px; /* [1] */

			@include mq($until: $header-breakpoint-tablet) {
				width: 50vw;

				// Only apply the following styles if the input is empty
				// and if it doesn't has focus
				&[value=""]:not(:focus) {
					width: 0;
					padding-left: 0;

					~ .form__btn {
						z-index: -1; /* [2] */
					}
				}
			}
		}

		.form__btn {
			width: 40px;
		}
	}
}





/* Breadcrumb
   ========================================================================== */

.faq__breadcrumb {
	justify-content: left;
	width: max-content;
	max-width: 100%;

	&:hover .nav__link {
		opacity: 1;
	}

	.nav__link {
		opacity: .5;

		&:hover {
			opacity: 1;

			& ~ .nav__link {
				opacity: .5;
			}
		}

		.icon {
			vertical-align: bottom;
		}
	}
}





/* Nav
   ========================================================================== */

.faq__nav {
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 0.125em;

	position: relative;
	z-index: 1;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	background-color: $header-color-background;
	box-sizing: border-box;
	width: 100%;
	max-height: calc( var(--window-height) - var(--blocked-height) );
	padding-bottom: 60px;
	transition: transform $header-transition, visibility $header-transition;

	margin-left: -20px;
	margin-right: -20px;
	width: calc(100% + 40px);

	@include mq($from: $header-breakpoint-tablet) {
		overflow: visible;
		width: max-content;
		max-width: 33.4%;
		height: 100%;
		padding-bottom: 0;

		// Cover the space left to the nav
		&::before {
			content: "";
			position: absolute;
			z-index: 1;
			top: 0;
			right: 100%;
			background-color: inherit;
			width: 100%;
			height: 100%;
		}

		&::after {
			content: "";
			position: absolute;
			z-index: -1;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: inherit;
			border-right: 1px solid $header-color-border;
		}
	}

	> .nav__item {

		&:hover,
		&.is-open {

			> .nav__link {
				font-weight: bold;
			}
		}

		> .nav__link {

			@include mq($from: $header-breakpoint-desktop) {
				white-space: nowrap;
			}

			// Only matches if `.nav__link` is not the only child of its parent
			&:not(:only-child)::after {
				font-size: 90%;
				content: "〉";
				margin-left: 12px;
			}
		}
	}
}


.faq__nav-level {
	width: 100%;
	height: 100%;
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	transform: translate3D(-100%, 0, 0);

	margin: 0;

	@include mq($from: $header-breakpoint-tablet) {
		position: absolute;
		box-sizing: content-box; /* [1] */
		left: 100%;
		z-index: -1;
		padding-bottom: 0;

		min-width: min-content;
		max-width: none;

		&::before {
			content: none;
		}
	}

	.nav__item.is-open > & {
		visibility: visible;
		transform: translate3D(0, 0, 0);

		// Make sure all menu levels close when closing the menu
		.flyout:not(.is-open) &,
		.flyout.is-closing & {
			transform: translate3D(-100%, 0, 0);
		}
	}

	// Remove border of thrid nav-level
	.faq__nav-level::after {
		border-right: none;
	}
}

/* ==========================================================================
   # GLOBAL
   ========================================================================== */

/**
 * Global styling goes here, anything that styles elements directly without
 * a class or something (this includes attribute selectors).
 */

html {
  box-sizing: border-box;
}
*,
::after,
::before {
	box-sizing: inherit;
}

[hidden] {
	display: none;
}

body {
	font-family: Branding, sans-serif;
	margin: 0;
}


// TEST AREA

a {
	color: $color-highlight;

	&:hover {
		color: $color-highlight-hover;
	}
}

h1 {
	font-size: var(--font-size-heading-xl);
    font-weight: 300;
    color: $color-oase-newdarkblue;
}

h2 {
	font-size: var(--font-size-heading-l);
    font-weight: 300;
    color: $color-oase-newdarkblue;
}

h3 {
	font-size: var(--font-size-heading-m);
    font-weight: 300;
    color: $color-oase-newdarkblue;
}

h4 {
	font-size: var(--font-size-heading-s);
    font-weight: 300;
    color: $color-oase-newdarkblue;
}

h5 {
	font-size: var(--font-size-text-xl);
    color: $color-oase-newdarkblue;
}

h6 {
	font-size: var(--font-size-text-l);
    color: $color-oase-newdarkblue;
}

p {
	font-size: var(--font-size-text-l);
}

b, strong {
    font-weight: 700;
}

dl,
li,
ol,
ul {
	font-size: var(--font-size-text-m);
}

input,
select,
textarea {
	font-size: var(--font-size-text-m);
}

button {
	font-size: var(--font-size-text-xs);
}

/**
 * bootstrap carousel additions
 * Component: Gallery
 */

.carousel {
    background-color: #000;
    z-index: 0;

    .carousel-inner {
        background-color: #000;
    }

    .carousel-indicators {
        opacity: .33;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        width: $carousel-control-icon-width*0.75;
        height: $carousel-control-icon-width*2.7;
    }

    .carousel-item .picture-teaser .teaser-content {
        left: 15%;
        right: 15%;
    }

    .carousel-item .picture-teaser .teaser-content {
        width: 70%;

        p.text-muted {
            opacity: .5;
        }
    }

    @media (min-width: 40em) {
        .carousel-item .picture-teaser .teaser-content {
            width: 50%;

            .btn-light {
                color: $color-oase-newdarkblue!important;
            }
        }
        .carousel-item .picture-teaser .teaser-content.text-left {
            left: 15%;
            right: 35%;
        }
        .carousel-item .picture-teaser .teaser-content.text-center {
            left: 25%;
            right: 25%;
        }
        .carousel-item .picture-teaser .teaser-content.text-right {
            left: 35%;
            right: 15%;
        }
    }

    @media (min-width: 62em) {
        .carousel-item .picture-teaser .teaser-content {
            width: 60%;
        }

        .carousel-item .picture-teaser .teaser-content.text-center {
            left: 20%;
            right: 20%;
        }
    }

    /*@media (min-width: 62em) {
        .carousel-item .picture-teaser .teaser-content {
            width: 40%;
        }
        .carousel-item .picture-teaser .teaser-content.text-left {
            left: 15%;
            right: 45%;
        }
        .carousel-item .picture-teaser .teaser-content.text-center {
            left: 30%;
            right: 30%;
        }
        .carousel-item .picture-teaser .teaser-content.text-right {
            left: 45%;
            right: 15%;
        }
    }

    @media (min-width: 120em) {
        .carousel-item .picture-teaser .teaser-content {
            width: 30%;
        }
        .carousel-item .picture-teaser .teaser-content.text-left {
            left: 15%;
            right: 55%;
        }
        .carousel-item .picture-teaser .teaser-content.text-center {
            left: 35%;
            right: 35%;
        }
        .carousel-item .picture-teaser .teaser-content.text-right {
            left: 55%;
            right: 15%;
        }
    }*/

    @media (max-width: 400px) {
        .carousel-item [class^="pic-container-"] {
            padding-bottom:130%;
        }
        .carousel-item .picture-teaser .teaser-content.text-left,
        .carousel-item .picture-teaser .teaser-content.text-center,
        .carousel-item .picture-teaser .teaser-content.text-right {
            text-align: center !important;
        }
    }

    @media (max-width: 840px) {
        .standalone [class^="pic-container-"] {
            padding-bottom:100%;
        }
        .carousel-item [class^="pic-container-"] {
            padding-bottom:100%;
        }
    }

    /* falsche AbstÃ¤nde im Carousel Teaser (Head, copy, Button) */

    @media screen and (min-width: 36em) {
        div.carousel-item > div > div > div.teaser-content.text-center > p {
            line-height: 35px !important;
            padding-bottom: 2.5em !important;
        }
    }

    @media screen and (min-width: 62em) {
        div.carousel-item > div > div > div.teaser-content.text-center > p {
            padding-bottom: 3.5em !important;
        }
    }
}

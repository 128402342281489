/* ==========================================================================
   # RATIO
   ========================================================================== */

/**
 * Create ratio-bound content blocks, to keep media (e.g. images, videos) in
 * their correct aspect ratios.
 *
 * http://alistapart.com/article/creating-intrinsic-ratios-for-video
 *
 * 1. Default is a 1:1 ratio (i.e. a perfect square).
 */

.ratio {
	position: relative;
	display: block;

	&::before {
		content: "";
		display: block;
		width: 100%;
		padding-bottom: 100%; /* [1] */
	}
}

.ratio__content {
	position: absolute;
	top:    0;
	bottom: 0;
	left:   0;
	right:  0;
	height: 100%;
	width:  100%;
}



/* Ratio variants
   ========================================================================== */

.ratio--4\:1::before {
	padding-bottom: percentage(1/4);
}

.ratio--2\:1::before {
	padding-bottom: percentage(1/2);
}

.ratio--16\:9::before {
	padding-bottom: percentage(9/16);
}

.ratio--4\:3::before {
	padding-bottom: percentage(3/4);
}

.ratio--1\:1::before {
	padding-bottom: percentage(1/1);
}

